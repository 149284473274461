export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    GET_DEVICE_LIST_REQUEST: 'GET_DEVICE_LIST_REQUEST',
    GET_DEVICE_LIST_SUCCESS: 'GET_DEVICE_LIST_SUCCESS',
    GET_DEVICE_LIST_FAILURE: 'GET_DEVICE_LIST_FAILURE',

    GET_FIRMWARE_LIST_REQUEST: 'GET_FIRMWARE_LIST_REQUEST',
    GET_FIRMWARE_LIST_SUCCESS: 'GET_FIRMWARE_LIST_SUCCESS',
    GET_FIRMWARE_LIST_FAILURE: 'GET_FIRMWARE_LIST_FAILURE',

    ADD_DEVICE_REQUEST: 'ADD_DEVICE_REQUEST',
    ADD_DEVICE_SUCCESS: 'ADD_DEVICE_SUCCESS',
    ADD_DEVICE_FAILURE: 'ADD_DEVICE_FAILURE',

    GET_HARDWARE_LIST_REQUEST: 'GET_HARDWARE_LIST_REQUEST',
    GET_HARDWARE_LIST_SUCCESS: 'GET_HARDWARE_LIST_SUCCESS',
    GET_HARDWARE_LIST_FAILURE: 'GET_HARDWARE_LIST_FAILURE',

    CREATE_FIRMWARE_LIST_REQUEST: 'CREATE_FIRMWARE_LIST_REQUEST',
    CREATE_FIRMWARE_LIST_SUCCESS: 'CREATE_FIRMWARE_LIST_SUCCESS',
    CREATE_FIRMWARE_LIST_FAILURE: 'CREATE_FIRMWARE_LIST_FAILURE',

    CREATE_HARDWARE_LIST_REQUEST: 'CREATE_HARDWARE_LIST_REQUEST',
    CREATE_HARDWARE_LIST_SUCCESS: 'CREATE_HARDWARE_LIST_SUCCESS',
    CREATE_HARDWARE_LIST_FAILURE: 'CREATE_HARDWARE_LIST_FAILURE',

    UPDATE_HARDWARE_REQUEST: 'UPDATE_HARDWARE_REQUEST',
    UPDATE_HARDWARE_SUCCESS: 'UPDATE_HARDWARE_SUCCESS',
    UPDATE_HARDWARE_FAILURE: 'UPDATE_HARDWARE_FAILURE',

    UPDATE_FIRMWARE_REQUEST: 'UPDATE_FIRMWARE_REQUEST',
    UPDATE_FIRMWARE_SUCCESS: 'UPDATE_FIRMWARE_SUCCESS',
    UPDATE_FIRMWARE_FAILURE: 'UPDATE_FIRMWARE_FAILURE',

    GET_FIRMWARE_UPDATE_LIST_REQUEST: 'GET_FIRMWARE_UPDATE_LIST_REQUEST',
    GET_FIRMWARE_UPDATE_LIST_SUCCESS: 'GET_FIRMWARE_UPDATE_LIST_SUCCESS',
    GET_FIRMWARE_UPDATE_LIST_FAILURE: 'GET_FIRMWARE_UPDATE_LIST_FAILURE',
};
