import {actionTypes} from "./actionTypes";
import {requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    hardwareCreated: undefined,
    firmwareCreated: undefined,
    hardwareUpdated: undefined,
    firmwareUpdated: undefined,
    firmwareInfoSubmitting: undefined,
    hardwareInfoSubmitting: undefined,
    errorMessageForHardwareUpdate: undefined
};

/*Reducer for Device's redux store*/
export const otaReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_HARDWARE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                hardwareList: undefined,
                hardwareCreated: undefined,
                hardwareUpdated: undefined
            };

        case actionTypes.GET_HARDWARE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                hardwareList: action.payload
            };

        case actionTypes.GET_HARDWARE_LIST_FAILURE:
            // toast.error("Can't load the hardware list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                firmwares: undefined,
                firmwareCreated: undefined,
                firmwareUpdated: undefined
            };

        case actionTypes.GET_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                firmwares: action.payload
            };

        case actionTypes.GET_FIRMWARE_LIST_FAILURE:
            // toast.error("Can't get the firmware list! Please contact with admin.");
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_FIRMWARE_UPDATE_LIST_REQUEST:

            return {
                ...state,
                listLoading: true,
                otaList: undefined
            };

        case actionTypes.GET_FIRMWARE_UPDATE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                otaList: action.payload,
            };

        case actionTypes.GET_FIRMWARE_UPDATE_LIST_FAILURE:
            toast.error("Can't load the OTA list! Please contact with admin.")
            return {
                ...state,
                listLoading: false,
            };

        case actionTypes.CREATE_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
                firmwareCreated: undefined,
                firmwareInfoSubmitting: true
            };
        case actionTypes.CREATE_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                firmwareCreated: requestCycle.success,
                firmwareInfoSubmitting: false
            };
        case actionTypes.CREATE_FIRMWARE_LIST_FAILURE:
            return {
                ...state,
                firmwareCreated: requestCycle.failed,
                errorMessage: action.payload.error.message,
                firmwareInfoSubmitting: false
            };

        case actionTypes.UPDATE_FIRMWARE_REQUEST:
            return {
                ...state,
                firmwareUpdated: undefined,
                firmwareInfoSubmitting: true
            };
        case actionTypes.UPDATE_FIRMWARE_SUCCESS:
            return {
                ...state,
                firmwareUpdated: requestCycle.success,
                firmwareInfoSubmitting: false
            };
        case actionTypes.UPDATE_FIRMWARE_FAILURE:
            return {
                ...state,
                firmwareUpdated: requestCycle.failed,
                errorMessageForFirmwareUpdate: action.payload.error.message,
                firmwareInfoSubmitting: false
            };

        case actionTypes.CREATE_HARDWARE_LIST_REQUEST:
            return {
                ...state,
                hardwareCreated: undefined,
                hardwareInfoSubmitting: true
            };
        case actionTypes.CREATE_HARDWARE_LIST_SUCCESS:
            return {
                ...state,
                hardwareCreated: requestCycle.success,
                hardwareInfoSubmitting: false
            };
        case actionTypes.CREATE_HARDWARE_LIST_FAILURE:
            return {
                ...state,
                hardwareCreated: requestCycle.failed,
                errorMessageForHardwareCreation: action.payload.error.message,
                hardwareInfoSubmitting: false
            };

        case actionTypes.UPDATE_HARDWARE_REQUEST:
            return {
                ...state,
                hardwareUpdated: undefined,
                hardwareInfoSubmitting: true
            };
        case actionTypes.UPDATE_HARDWARE_SUCCESS:
            return {
                ...state,
                hardwareUpdated: requestCycle.success,
                hardwareInfoSubmitting: false
            };
        case actionTypes.UPDATE_HARDWARE_FAILURE:
            return {
                ...state,
                hardwareUpdated: requestCycle.failed,
                errorMessageForHardwareUpdate: action.payload.error.message,
                hardwareInfoSubmitting: false
            };

        case "RETURN_TO_INITIAL":
            return initialState
        default:
            return state;
    }
};
