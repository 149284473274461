import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
};

/*Reducer for Grid's redux store*/
export const rolesReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_ROLE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                roleList: undefined,
                roleCreated: undefined,
                successMessageRoleCreation: undefined,
                roleUpdated: undefined
            };
        case actionTypes.GET_ROLE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                roleList: action.payload
            };
        case actionTypes.GET_ROLE_LIST_FAILURE:
            toast.error("Can't load the user list! Please contact admin.")
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_ORGANISATIONS_REQUEST:
            return {
                ...state,
                organisationListLoading: true,
                organisations: undefined
            };
        case actionTypes.GET_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                organisationListLoading: false,
                organisations: action.payload
            };
        case actionTypes.GET_ORGANISATIONS_FAILURE:
            toast.error("No organisation is available for selection, because: " + action.payload.error.msg);
            return {
                ...state,
                organisationListLoading: false
            };

        case actionTypes.COLLECT_FEATURE_LIST_REQUEST:
            return {
                ...state,
                featureListCollecting: true,
                featureList: undefined
            };
        case actionTypes.COLLECT_FEATURE_LIST_SUCCESS:
            return {
                ...state,
                featureListCollecting: false,
                featureList: action.payload
            };
        case actionTypes.COLLECT_FEATURE_LIST_FAILURE:
            return {
                ...state,
                featureListCollecting: false,
                featureList: undefined
            };

        case actionTypes.CREATE_ROLE_REQUEST:
            return {
                ...state,
                roleInfoSubmitting: true,
                roleCreated: undefined
            };
        case actionTypes.CREATE_ROLE_SUCCESS:
            return {
                ...state,
                roleInfoSubmitting: false,
                roleCreated: requestCycle.success,
                successMessageRoleCreation: action.payload,
            };
        case actionTypes.CREATE_ROLE_FAILURE:
            return {
                ...state,
                roleInfoSubmitting: false,
                roleCreated: requestCycle.failed,
                errorMessageRoleCreation: action.payload.error
            };

        case actionTypes.UPDATE_ROLE_REQUEST:
            return {
                ...state,
                roleInfoSubmitting: true,
                roleUpdated: undefined
            };
        case actionTypes.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                roleInfoSubmitting: false,
                roleUpdated: requestCycle.success,
                successMessageRoleUpdate: action.payload
            };
        case actionTypes.UPDATE_ROLE_FAILURE:
            return {
                ...state,
                roleInfoSubmitting: false,
                roleUpdated: requestCycle.failed,
                errorMessageRoleUpdate: action.payload.error
            };

        default:
            return state;
    }
};
