import React from 'react';
import {connect} from 'react-redux';
import {Badge} from "react-bootstrap";
import DateTimeFormat from "../../utils/dateFormat/DateTimeFormat";
import DataTable from "../dataTable/DataTable";


const FwUpdateHistoriesTable = ({ histories, ...props }) => {

    // Table header and data representation
    const FirmwareUpdateListColumns = [
        {
            field: 'dongle_serial_number',
            title: 'Dongle',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'old_firmware_version',
            title: 'Old FW Version',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
            // disableClick: true,
        },
        {
            field: 'new_firmware_version',
            title: 'New FW Version',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
        {
            field: 'is_successful',
            title: 'FW Installation Status',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                let backgroundColorCode = "#CB152B"
                let textColor = "#ffffff"
                let statusText = "Failed"
                if (rowData.is_successful) {
                    backgroundColorCode = rowData.old_firmware_version === rowData.new_firmware_version? '#0b6e4f':'#96FFCC'
                    statusText = rowData.old_firmware_version === rowData.new_firmware_version? 'Installed':'Successful'
                    textColor = rowData.old_firmware_version !== rowData.new_firmware_version? '#0B6E4F':textColor
                }
                return   <>
                    <Badge style={{"backgroundColor": backgroundColorCode, color: textColor}}>{statusText}</Badge>
                </>
            }
        },
        {
            field: 'config_data_status',
            title: 'Config Validation Status',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                let backgroundColorCode = "#CB152B"
                let textColor = "#ffffff"
                let statusText = "Failed"
                if (rowData.config_data_status === 'successful') {
                    backgroundColorCode = '#96FFCC'
                    textColor = "#0b6e4f"
                    statusText = 'Successful'
                } else if (rowData.config_data_status === 'saved') {
                    backgroundColorCode = '#0b6e4f'
                    statusText = 'Saved'
                    textColor = "#ffffff"
                } else if (rowData.config_data_status !== 'failed') {
                    backgroundColorCode = '#FACC15'
                    statusText = 'Pending'
                    textColor = "#484848"
                }
                return   <>
                    <Badge style={{"backgroundColor": backgroundColorCode, color: textColor}}>{statusText}</Badge>
                </>
            }
        },
        {
            field: 'bms_validation_status',
            title: 'Config Save Status',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                let backgroundColorCode = "#CB152B"
                let textColor = "#ffffff"
                let statusText = "Failed"
                if (rowData.bms_validation_status === 'successful') {
                    backgroundColorCode = '#0b6e4f'
                    statusText = 'Complete'
                } else if (rowData.bms_validation_status !== 'failed') {
                    backgroundColorCode = '#FACC15'
                    statusText = 'Pending'
                    textColor = "#484848"
                }
                return   <>
                    <Badge style={{"backgroundColor": backgroundColorCode, color: textColor}}>{statusText}</Badge>
                </>
            }
        },
        {
            field: 'created_at',
            title: 'Initiated At',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: rowData => (rowData.created_at? new DateTimeFormat(rowData.created_at).localDisplayTime : "--")
        },
        {
            field: 'created_by_name',
            title: 'Initiated By',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
    ]


    return (
        <>
            <DataTable
                language={props.language}
                noDataAvailableMessageInEnglish={'No OTA Updates available'}
                noDataAvailableMessageInBangla={'কোন ওটিএ আপডেট নেই'}
                columns={FirmwareUpdateListColumns}
                data={histories? histories.map((item,index)=>({...item, sl: index+1})):[]}
                asyncPagination={false}
                pagination={true}
                pageSize={10}
            />
        </>
    );
};

FwUpdateHistoriesTable.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps)(FwUpdateHistoriesTable)