import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../smartDongles/views/components";
import {SolInput} from "../../../components/SolStyledComponents/components";
import {DataTableContainer} from "../../rentLog/utils";
import {LoadingSpinner} from '../../../components/LoadingSpinnerForDataTable'
import DataTable from "../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {appEventActions} from "../../appEvents/actions";
import {actions} from "../actions";
import DateTimeFormat from "../../../utils/dateFormat/DateTimeFormat";
import {Button} from "react-bootstrap";
import {getServiceName} from "../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../auth/authorization";
import {toBengaliNumber} from "bengali-number";
import {useSubheader} from "../../../../_metronic/layout";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import { parseParams} from "../../../utils/utilityFunctions";


const HardwareList = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    const [page, setPage] = useState(1)
    const [queryParams, setQueryParams] = useState(null)
    const [initialfilterParam, setInitialfilterParam] = useState(null)
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null)
    const [urlIsEdited, setUrlIsEdited] = useState(false)
    const deviceServiceName = getServiceName('deviceService');
    const hardwareFeatureName = getFeatureName('hardwareFeature');
    const createActionName = getActionName('createAction');
    const updateActionName = getActionName('updateAction');
    const subHeader = useSubheader()

    const hardwareListColumns = [
        {
            field: 'sl',
            title: language === 'EN'? 'Sl':'ক্রম',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            width: "5"
            // render: rowData => (rowData.app_event_time? new DateTimeFormat(rowData.app_event_time).displayTime : "--")
        },
        {
            field: 'version',
            title: language === 'EN'? 'Version':'হার্ডওয়্যারের সংস্করণ',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
            // disableClick: true,
        },
        {
            field: 'status',
            title: language === 'EN'? 'Status':'বর্তমান অবস্থা',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textTransform: 'capitalize',
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'sk',
            title: language === 'EN'? 'Creation Date':'মুক্তির তারিখ',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: rowData => (rowData.sk? new DateTimeFormat(rowData.sk).displayTime : "--")
        },
        // {
        //     field: 'staging_release_date',
        //     title: language === 'EN'? 'Staging Release Date':'ষ্টেজিং থেকে মুক্তির তারিখ',
        //     emptyValue: ()=>{
        //         return "N/A"
        //     },
        //     render: rowData => (rowData.staging_release_date? new DateTimeFormat(rowData.staging_release_date).displayTime : "--")
        //
        // },
        {
            field: 'created_by_name',
            title: language === 'EN'? 'Created by':'হার্ডওয়্যার তৈরি হয়েছে যার দ্বারা',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
    ]

    const {
        GET_HARDWARE_LIST,
        RETURN_TO_INITIAL,
        hardwareList,
    } = props


    useEffect(()=>{
        setAuthorization(props.authorization)
        setLanguage(props.language)
        if (checkAuthorization(props.authorization, deviceServiceName, hardwareFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant={"warning"} size={"sm"}
                            onClick={()=>{goToAddHardware()}}>
                        <i className="fa fa-plus"/>{props.language === 'EN'? 'Create Hardware':'হার্ডওয়্যার তৈরি করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)
        GET_HARDWARE_LIST({})
        // if (!props.history.location.search) {
        //     setPage(1)
        //     props.history.push({
        //         search: 'page=1'
        //     })
        // }
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            GET_HARDWARE_LIST({page: page})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //

    const goToAddHardware = () =>{
        props.history.push({
            pathname: '/ota/hardwares/new',
            purpose: 'create'
        })
    }

    const goToUpdateHardware = (rowData) =>{
        props.history.push({
            pathname: '/ota/hardwares/edit',
            purpose: 'edit',
            hardwareData: rowData
        })
    }

    const onChangePage = (newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        props.history.push({
            search: searchText
        })
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"Mobile Application Events"} showBackButton={false} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>{language === 'EN'? 'Hardware List':'হার্ডওয়্যারের তালিকা'}</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            {/*<div className={'col-md-4'}>*/}
                            {/*    <SolInput*/}
                            {/*        type={'text'}*/}
                            {/*        placeholder={'Device Serial Number'}*/}
                            {/*        onKeyPress={searchDevice}*/}
                            {/*        onChange={(event) => {*/}
                            {/*            if (event.target.value === '') {*/}
                            {/*                onSearch(event);*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>
            <div>
                <DataTableContainer>
                    {props.listLoading !== true ? <DataTable
                        language={language}
                        noDataAvailableMessageInBangla={'কোন হার্ডওয়্যার নেই'}
                        columns={hardwareListColumns}
                        data={hardwareList?hardwareList.map((item,index)=>({...item, sl: language === 'EN'? index+1:toBengaliNumber(index+1)})):[]}
                        // asyncPagination={true}
                        // count={hardwareList?.count}
                        // itemsPerPage={hardwareList?.page_size}
                        // onChangePage={onChangePage}
                        // page={page}
                        asyncPagination={false}
                        pagination={true}
                        pageSize={10}
                        actionColumnIndex={-1}
                        actions={checkAuthorization(authorization, deviceServiceName, hardwareFeatureName, [updateActionName])? [{}]:[]}
                        actionButtonVariant={['warning']}
                        actionButtonSize={'sm'}
                        actionButtonClickEvent={[goToUpdateHardware]}
                        actionButtonText={language === 'EN'? ['Update']:['সম্পাদনা করুন']}
                    /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating hardware list ..': 'হার্ডওয়্যারের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                </DataTableContainer>
            </div>
        </ContentWrapper>
    );
};


export default connect((state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.otaReducer.isLoading,
        hardwareList: state.otaReducer.hardwareList,
        listLoading: state.otaReducer.listLoading,
    }
}, {...actions}) (HardwareList);
