import {Redirect, Switch} from "react-router-dom";
import React from "react";
import RentLog from "./views/rentLog/rentLog";
// import CheckOut from "./views/checkOut/checkOut";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const RentLogRouter =(props)=> {

    return (
        <Switch>
            {/* Following url/path is added as it has been used in the automated mail throughout the company*/}
            <Redirect
                exact={"true"}
                from={"/checkout/list"}
                to={"/rents"}
            />

            <Redirect
                exact={"true"}
                from={"/rents"}
                to={"/rents/logs"}
            />

            {/* Routes to check out List*/}
            <SecuredRoute
                path={"/rents/logs"}
                component={RentLog}
                service={getServiceName('garageService')}
                feature={getFeatureName('batteryRentFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
