import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router";
import {Button, Form, Spinner} from "react-bootstrap";
import {SolInput} from "../../../components/SolStyledComponents/components";
import Select from "react-select";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {actions} from "../actions";
import {Toolbar} from "../../users/views/components";
import {toast} from "react-toastify";
import {requestCycle} from "../utils";
import {useSubheader} from "../../../../_metronic/layout";

const CreateHardware = props => {

    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const [language, setLanguage] = useState("EN");
    const location = useLocation();
    const subHeader = useSubheader();

    const [edit, setEdit] = useState(!!sessionStorage.getItem('edit'));
    const [hardwareVersion, setHardwareVersion] = useState(sessionStorage.getItem('version')? sessionStorage.getItem('version'):"");
    const [versionIsActive, setVersionIsActive] = useState(sessionStorage.getItem('isactive')? sessionStorage.getItem('isactive'):false);

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setActionButtons(null)

        if (location.purpose && location.purpose === 'create') {
            setEdit(false)
            sessionStorage.setItem('edit', false)
        }
        if (location.hardwareData) {
            sessionStorage.setItem('edit', true)
            sessionStorage.setItem('pk', location.hardwareData.pk)
            let version = location.hardwareData.version
            sessionStorage.setItem('version', version)
            let isActive = location.hardwareData.status === 'active'
            sessionStorage.setItem("isactive", isActive)
            setEdit(true)
            setHardwareVersion(version)
            setVersionIsActive(isActive)
        }
    }, [])

    useEffect(()=>{
        if (sessionStorage.getItem('edit') === 'false') {
            setHardwareVersion("")
            setVersionIsActive("")
            clearSessionStorages()
        }
    },[edit])

    const clearSessionStorages = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('pk')
        sessionStorage.removeItem('version')
        sessionStorage.removeItem('isactive')
    }

    useEffect(() => {
        if (props.hardwareCreated === requestCycle.success) {
            if (language === 'EN') {
                toast.success("Hardware Created Successfully!");
            } else {
                toast.success("সফলভাবে হার্ডওয়্যার তৈরি হয়েছে!");
            }
            props.history.push('/ota/hardwares')
        } else if (props.hardwareCreated === requestCycle.failed) {
            if (language === 'EN') {
                toast.error("Can't create Hardware. " + props.errorMessageForHardwareCreation)
            } else {
                toast.error("হার্ডওয়্যার তৈরি করা সম্ভব হচ্ছে না!")
            }
        }
    }, [props.hardwareCreated])

    useEffect(() => {
        if (props.hardwareUpdated === requestCycle.success) {
            if (language === 'EN') {
                toast.success("Hardware is updated successfully!");
            } else {
                toast.success("সফলভাবে হার্ডওয়্যার সম্পাদনা হয়েছে!");
            }
            props.history.goBack()
        } else if (props.hardwareUpdated === requestCycle.failed) {
            if (language === 'EN') {
                toast.error("Can't update the Hardware. " + props.errorMessageForHardwareUpdate)
            } else {
                toast.error("হার্ডওয়্যার সম্পাদনা করা সম্ভব হচ্ছে না!")
            }
        }
    }, [props.hardwareUpdated])

    const onSubmit=(data)=>{
        data = {
            ...data,
            status: data.status? "active":"inactive"
        }
        if (!edit) {
            props.CREATE_HARDWARE_LIST(data)
        } else {
            data["hardware_guid"] = sessionStorage.getItem("pk")
            props.UPDATE_HARDWARE(data)
        }
    }
    return (
        <ContentWrapper pageTitle={'Create hardware'} showCardHeader={false} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{edit? (language === "EN"? "Update hardware":"হার্ডওয়্যার সম্পাদনা করুন"):(language === "EN"? "Create hardware":"হার্ডওয়্যার তৈরি করুন")}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Label>{language === 'EN'? 'Hardware Version':'হার্ডওয়্যার ভার্সন'}<span className="required text-danger">*</span></Form.Label>
                                    <SolInput
                                        type="text"
                                        name={"version"}
                                        value={hardwareVersion}
                                        ref={register({
                                            required: language === 'EN'? 'Please provide above information!':'দয়া করে উপরের তথ্যটি দিন!',
                                            pattern: {
                                                value: /^\d+(\.\d+)*$/i,
                                                message: language === "EN"? "Invalid hardware version!":"হার্ডওয়্যার ভার্সনটি সঠিক নয়!"
                                            }
                                        })}
                                        placeholder={language === 'EN'? "Enter Hardware version":"হার্ডওয়্যার ভার্সন লিখুন"}
                                        autoComplete={"off"}
                                        onChange = {(e) => {
                                            let value = e.target.value
                                            if (value) {
                                                setHardwareVersion(value)
                                            } else {
                                                setHardwareVersion("")
                                            }
                                        }}
                                    />
                                    {errors.version && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.version.message}</div>}

                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Check type="checkbox"
                                                name={'status'}
                                                checked={versionIsActive}
                                                label={language === "EN"? "Active":"সক্রিয়"}
                                                ref={register()}
                                                onChange={() => {setVersionIsActive(!versionIsActive)}}
                                    />
                                    {errors.status && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.status.message}</div>}

                                </Form.Group>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Button variant="dark" size={'md'} onClick={() => {
                                    clearSessionStorages()
                                    props.history.goBack()
                                }} style={{
                                    backgroundColor: '#8C8C9B',
                                    outline: '#8C8C9B',
                                    border: '#8C8C9B',
                                }}>
                                    <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Close':'বন্ধ করুন'}
                                </Button>
                                <Button variant="warning" type="submit" className={"ml-3"} disabled={props.hardwareInfoSubmitting}>
                                    {props.hardwareInfoSubmitting===true? <><Spinner
                                        animation={'border'} size={'md'} variant={'light'}/></>:null}
                                    <i className='fa fa-check' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Save':'সংরক্ষণ করুন'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>


        </ContentWrapper>
    );
};


export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.otaReducer.isLoading,
        errorMessageForHardwareCreation: state.otaReducer.errorMessageForHardwareCreation,
        hardwareInfoSubmitting: state.otaReducer.hardwareInfoSubmitting,
        hardwareCreated: state.otaReducer.hardwareCreated,
        hardwareUpdated: state.otaReducer.hardwareUpdated,
        errorMessageForHardwareUpdate: state.otaReducer.errorMessageForHardwareUpdate
    }
}, {...actions}) (CreateHardware);
