export const formulateApiRequestDataFromSelectedItems = (items, keyUsed='pk') => {
    let values = ''
    for (let i=0; i<items.length; i++) {
        if (i === 0) {
            values += items[i][keyUsed]
        } else {
            values += ',' + items[i][keyUsed]
        }
    }
    return values
}