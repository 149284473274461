import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import HardwareList from "./views/HardwareList";
import FirmwareList from "./views/FirmwareList";
import FirmwareUpdateHistory from "./views/FirmwareUpdateHistory";
import CreateFirmware from "./views/CreateFirmware";
import CreateHardware from "./views/CreateHardware";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const OtaRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the device if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/ota"}
                to={"/ota/hardwares"}
            />

            {/* Routes to Hardware List*/}
            <SecuredRoute
                exact={"true"}
                path={"/ota/hardwares"}
                component={HardwareList}
                service={getServiceName('deviceService')}
                feature={getFeatureName('hardwareFeature')}
                action={getActionName('listAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/hardwares/new"}
                component={CreateHardware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('hardwareFeature')}
                action={getActionName('createAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/hardwares/edit"}
                component={CreateHardware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('hardwareFeature')}
                action={getActionName('updateAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/firmwares"}
                component={FirmwareList}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('listAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/firmwares/new"}
                component={CreateFirmware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('createAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/firmwares/edit"}
                component={CreateFirmware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('updateAction')}
            />
            <SecuredRoute
                path={"/ota/history"}
                component={FirmwareUpdateHistory}
                service={getServiceName('deviceService')}
                feature={getFeatureName('otaUpdateFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
