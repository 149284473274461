import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Button, Form, Spinner, ListGroup, Modal} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {LoadingSpinner, SolTypography} from '../../../../components';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import moment from "moment";
import {toast} from "react-toastify";
import {exportInExcel} from "../../../../utils/excelExport";
import {toBengaliNumber} from "bengali-number";
import {isNullorUndefined} from "../../../../utils/utilityFunctions";
import { parseParams} from "../../../../utils/utilityFunctions";
import {Controller, useForm} from "react-hook-form";
import '../customSwitch.css';
import ProvidedSmartBatteriesInfo from "./providedSmartBatteriesInfo"
import ActivitiesOfAGarage from "./activitiesOfAGarage"
import TransactionsOfAGarage from "./transactionsOfAGarage"
import {requestCycle} from "../../../devices/utils";
import {borrowersReducer} from "../../reducer";
import Stack from "@mui/material/Stack";
import {checkAuthorization} from "../../../auth/authorization";
import UpdateDueLimitOfAGarage from "./dueLimitUpdate";
import ManualCashPayment from "./manualCashPayment";
import {showNotifications} from "../../../../utils/notification";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import Switch from '@mui/material/Switch';
import {SolTextArea} from "../../../../components/SolStyledComponents/components";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const Borrowers = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [showFilter, setShowFilter] = useState(true);
    const [urlIsEdited, setUrlIsEdited] = useState(false);

    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState("");
    const [districtFromUrl, setDistrictFromUrl] = useState("");
    const [upazilas, setUpazilas] = useState([]);
    const [upazila, setUpazila] = useState("");
    const [upazilaFromUrl, setUpazilaFromUrl] = useState("");
    const [borrowers, setBorrowers] = useState("");
    const [borrower, setBorrower] = useState("");
    const [borrowerFromUrl, setBorrowerFromUrl] = useState("");
    const [garages, setGarages] = useState([]);
    const [orgsList, setOrgsList] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState("");
    const [branchFromUrl, setBranchFromUrl] = useState("");
    const subHeader = useSubheader();
    const [keyForBorrowerTable, setKeyForBorrowerTable] = useState(1);

    // Borrower (table row) selection related
    const [enableBorrowerSelection, setEnableBorrowerSelection] = useState(false)
    const [showButtonsRelatedToSelection, setShowButtonsRelatedToSelection] = useState(false)
    const [selectedBorrower, setSelectedBorrower] = useState(undefined)

    // Garage activation status update
    const [showInProgressModal, setShowInProgressModal] = useState(false);
    const [garageWhoseStatusIsBeingUpdated, setGarageWhoseStatusIsBeingUpdated] = useState(false);
    const [garageNewStatusForUpdate, setGarageNewStatusForUpdate] = useState('');
    const [garageDeactivationStatusIsBeingUpdated, setGarageDeactivationStatusIsBeingUpdated] = useState(false);
    const [batteryWisePayment, setBatteryWisePayment] = useState(false);

    // Garage details modal
    const [showGarageDetailsModal, setShowGarageDetailsModal] = useState(false);
    const [showgarageStatusChangeModal, setShowGarageStatusChangeModal] = useState(false);
    const [garageName, setGarageName] = useState('');
    const [garageID, setGarageID] = useState('');
    const [registeredOn, setRegisteredOn] = useState('');
    const [garageDistrict, setGarageDistrict] = useState('');
    const [garageUpazila, setGarageUpazila] = useState('');
    const [garageOwnerName, setGarageOwnerName] = useState('');
    const [garageOwnerPhoneNumber, setGarageOwnerPhoneNumber] = useState('');
    const [garageOperatorName, setGarageOperatorName] = useState('');
    const [garageOperatorPhoneNumber, setGarageOperatorPhoneNumber] = useState('');

    // Provided battery details modal
    const [showProvidedBatteryInfoModal, setShowProvidedBatteryInfoModal] = useState(false);
    const [requiredInfoToCollectSmartBatteriesInfo, setRequiredInfoToCollectSmartBatteriesInfo] = useState('');
    const [numberOfSmartBatteriesProvided, setNumberOfSmartBatteriesProvided] = useState('');

    // Transactions modal
    const [showTransactionsButton, setShowTransactionsButton] = useState(false);
    const [showTransactionsModal, setShowTransactionsModal] = useState(false);
    const [requiredInfoToCollectTransactions, setRequiredInfoToCollectTransactions] = useState('');
    const [dueLimitIsUpdated, setDueLimitIsUpdated] = useState(false);

    // Activities modal
    const [showActivitiesButton, setShowActivitiesButton] = useState(false);
    const [showActivitiesModal, setShowActivitiesModal] = useState(false);

    // Due limit update modal
    const [showDueLimitUpdateModal, setShowDueLimitUpdateModal] = useState(false);

    // Manual Cash Payment modal
    const [showManualCashPaymentButton, setShowManualCashPaymentButton] = useState(false);
    const [showManualCashPaymentModal, setShowManualCashPaymentModal] = useState(false);
    const [titleOfManualCashPaymentModal, setTitleOfManualCashPaymentModal] = useState('');

    // Permission related
    const [canSaveManualPayment, setCanSaveManualPayment] = useState(false)
    const [canSeeBorrowerActivity, setCanSeeBorrowerActivity] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [canSeeTransactions, setCanSeeTransactions] = useState(false)
    const transactionServiceName = getServiceName('transactionService')
    const garageServiceName = getServiceName('garageService')
    const transactionFeatureName = getFeatureName('transactionFeature')
    const garageFeatureName = getFeatureName('garageFeature')
    const settlementFeature = getFeatureName('settlementFeature')
    const manualPaymentFeatureName = getFeatureName('newDepositFeature')
    const listActionName = getActionName('listAction')
    const createActionName = getActionName('createAction')
    const updateActionName = getActionName('updateAction')

    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [language, setLanguage] = useState(null);
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);
    const [currentRowData, setCurrentRowData] = useState(null);

    const pageTitle = language === 'EN'? 'Borrowers':'ঋণগ্রহীতাসমূহ'

    const column1 = language === 'EN'? 'Name': 'নাম'
    const column2 = language === 'EN'? 'Contracts': 'ঋণের চুক্তি'
    const column3 = language === 'EN'? 'Receivable (Tk)': 'প্রাপ্য'
    const column4 = language === 'EN'? 'Recharge (Tk)': 'শোধ হয়েছে'
    const column5 = language === 'EN'? 'Overdue (Tk)': 'গ্যারেজের বাকি আছে'
    const column6 = language === 'EN'? 'Platform Fee Deductible (Tk)': 'প্লাটফর্ম ফী বাদ দিতে হবে'
    const column7 = language === 'EN'? 'Payment Receivable by Battery Financier(Tk)': 'ব্যাটারী স্পন্সরদের প্রাপ্য'
    const column8 = language === 'EN'? 'Activation Status': 'গ্যারেজ সক্রিয় আছে?'
    const column9 = language === 'EN'? 'Deactivate Whole Garage if there is Due': 'কোন পাওনা থাকলে গ্যারেজ বন্ধ করে দিন'

    const garageListColumns =  [
        {
            field: 'name',
            title: column1,
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span style={{whiteSpace: 'nowrap'}}>{rowData.garage_owner}</span><br/>
                    <a style={{color: "#F18D00", whiteSpace: 'nowrap'}} onClick={() => showGarageDetails(rowData)}>
                        {rowData.name}
                    </a><br/>
                    {rowData.upazila && rowData.district? <>
                        <span style={{whiteSpace: 'nowrap'}}>{rowData.upazila},</span><br/>
                        <span>{rowData.district}</span>
                    </>:rowData.district? <>{rowData.district}</>:rowData.upazila? <>{rowData.upazila}</>:null}
                </>
            }
        },
        {
            field: 'number_of_provided_batteries',
            title: column2,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    {rowData.total_number_of_devices? <>
                        <a style={{color: "#F18D00"}} onClick={() => showProvidedBatteryDetails(rowData)}>
                            <h4><strong>{rowData.total_number_of_devices}</strong></h4>
                        </a>
                    </>:<>
                        <h4>{rowData.total_number_of_devices}</h4>
                    </>}
                </>
            }
        },
        {
            field: 'receivable_from_garage',
            title: column3,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h4>{new Intl.NumberFormat('en-IN').format(rowData.payable_total.toFixed(2))}</h4>
                </>
            }
        },
        {
            field: 'deposited_by_garage',
            title: column4,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return (
                    <>
                        <h4>{new Intl.NumberFormat('en-IN').format(rowData.deposit_total.toFixed(2))}</h4>
                    </>
                )
            }
        },
        {
            field: 'overdue_total',
            emptyValue: ()=>{
                return "-"
            },
            title: column5,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                let dueLimitReached = false
                let styles = {color: "#CB152B"}
                if (rowData.overdue_total >= rowData.total_due_limit && rowData.overdue_total !== 0) {
                    dueLimitReached = true
                }
                return (
                    <div style={dueLimitReached? styles:{}}>
                        <h4>{new Intl.NumberFormat('en-IN').format(rowData.overdue_total.toFixed(2))}</h4>
                        {dueLimitReached? <>
                            <span style={{whiteSpace: 'nowrap'}}>Due limit reached</span>
                        </>:null}
                    </div>
                )
            }
        },
        {
            field: 'platform_fee_deductible',
            title: column6,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                let percentage = ((rowData.payable_total * 3) / 100).toFixed(2)
                return (
                    <h4>{new Intl.NumberFormat('en-IN').format(percentage)}</h4>
                )
            }
        },
        {
            field: 'payment_receivable_by_battery_mfg',
            title: column7,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                let percentage = ((rowData.payable_total * 97) / 100).toFixed(2)
                return   <>
                    <h4>{new Intl.NumberFormat('en-IN').format(percentage)}</h4>
                </>
            }
        },
        {
            field: 'activation_status',
            title: column8,
            disableClick: true,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                return   <>
                    <Form.Check
                        size="lg"
                        style={{ cursor: 'pointer',  whiteSpace: 'nowrap' }}
                        type="switch"
                        id={rowData.pk}
                        defaultChecked={rowData.status === 'active'}
                        label=""
                        onChange={(e) => {
                            setCurrentRowData(rowData)
                            setGarageWhoseStatusIsBeingUpdated(rowData.name)
                            setShowGarageStatusChangeModal(true)
                        }}
                    />
                </>
            }
        },
        {
            field: 'battery_wise_payment',
            title: column9,
            disableClick: true,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                return   <>
                    <Form.Check
                        size="lg"
                        style={{ cursor: 'pointer',  whiteSpace: 'nowrap' }}
                        type="switch"
                        id={rowData.garage_id}
                        defaultChecked={!rowData.battery_wise_payment}
                        label=""
                        onChange={(e) => {
                            setGarageWhoseStatusIsBeingUpdated(rowData.name)
                            setShowInProgressModal(true)
                            let status = rowData.status
                            setGarageNewStatusForUpdate(status)
                            let payload = {
                                garage_guid: rowData.pk,
                                status: status,
                                battery_wise_payment: !rowData.battery_wise_payment
                            }
                            setGarageDeactivationStatusIsBeingUpdated(true)
                            setBatteryWisePayment(!rowData.battery_wise_payment)
                            UPDATE_GARAGE_ACTIVATION_STATUS(payload)
                        }}
                    />
                </>
            }
        }
    ]

    const {
        COLLECT_DISTRICTS,
        COLLECT_UPAZILAS_FOR_ALL,
        COLLECT_BORROWERS,
        GET_ORGANISATIONS,
        COLLECT_GARAGE_LIST,
        GET_BRANCHES_LIST,
        COLLECT_GARAGE_DETAILS,
        COLLECT_PROVIDED_SMART_BATTERIES_INFO,
        UPDATE_GARAGE_ACTIVATION_STATUS,
        MANUAL_CASH_PAYMENT
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        setPage(1)
        COLLECT_UPAZILAS_FOR_ALL("all")
        COLLECT_DISTRICTS("all")
        COLLECT_BORROWERS()
        GET_BRANCHES_LIST()
        if (props.isSolshareUser) {
            GET_ORGANISATIONS()
        }
        if (!props.history.location.search) {
            COLLECT_GARAGE_LIST()
        }

        setCanSaveManualPayment(checkAuthorization(props.authorization, transactionServiceName, manualPaymentFeatureName, [createActionName]))
        setCanSeeBorrowerActivity(checkAuthorization(props.authorization, transactionServiceName, transactionFeatureName, [listActionName]))
        setCanEdit(checkAuthorization(props.authorization, garageServiceName, garageFeatureName, [updateActionName]))
        setCanSeeTransactions(checkAuthorization(props.authorization, transactionServiceName, settlementFeature, [listActionName]))
        if (checkAuthorization(props.authorization, transactionServiceName, manualPaymentFeatureName, [createActionName]) ||
            checkAuthorization(props.authorization, transactionServiceName, transactionFeatureName, [listActionName]) ||
            checkAuthorization(props.authorization, transactionServiceName, settlementFeature, [listActionName]) ||
            checkAuthorization(props.authorization, garageServiceName, garageFeatureName, [updateActionName])) {
            setEnableBorrowerSelection(true)
        } else {
            setEnableBorrowerSelection(false)
        }

        if (checkAuthorization(props.authorization, garageServiceName, garageFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={goToAddBorrower} classes={classes} className={'ml-1'}>
                        <i className="fa fa-plus"/>{props.language === 'EN'? 'Add Borrower': 'ঋণগ্রহীতা নিবন্ধন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    // ----- Begin: Section related to Garage Registration //
    const goToAddBorrower = () => {
        props.history.push({
            pathname: '/borrowers/create',
            purpose: 'registration'
        })
    }
    // ----- End: Section related to Garage Registration //

    // ----- Begin: Section related to Garage Edit //
    const goToEditBorrower = (data) => {
        props.history.push({
            pathname: '/borrowers/edit',
            borrowerData: data,
            purpose: 'edit'
        })
    }
    // ----- End: Section related to Garage Edit //

    // ----- Begin: Section related to borrower selection //
    const onSelection = (data) => {
        let numberOfSelectedBorrowers = data.length
        if (numberOfSelectedBorrowers === 1) {
            setSelectedBorrower(data)
            setShowButtonsRelatedToSelection(true)
            if (data[0].leasing_model_enabled) {
                setShowTransactionsButton(false)
                setShowActivitiesButton(true)
                setShowManualCashPaymentButton(true)
            } else {
                setShowTransactionsButton(true)
                setShowActivitiesButton(false)
                setShowManualCashPaymentButton(false)
            }
        } else {
            setShowButtonsRelatedToSelection(false)
        }
    }
    // ----- End: Section related to borrower selection //

    // ----- Begin: Section related to garage status change ----- //
    useEffect(() => {
        if (props.updateGarageActivation) {
            setShowInProgressModal(false)
            if (garageDeactivationStatusIsBeingUpdated) {
                let statusText = batteryWisePayment? 'will not be deactivated, if there is due!':'will be deactivated, if there is due!'
                toast.success(garageWhoseStatusIsBeingUpdated + ' ' + statusText)
                setGarageDeactivationStatusIsBeingUpdated(false)
                setBatteryWisePayment(false)
            } else {
                let statusText = garageNewStatusForUpdate === 'active'? 'activated!':'deactivated'
                toast.success(garageWhoseStatusIsBeingUpdated + ' has been ' + statusText)
            }
            setShowButtonsRelatedToSelection(false)
            setGarageWhoseStatusIsBeingUpdated('')
            setGarageNewStatusForUpdate('')
            setShowGarageStatusChangeModal(false)
            triggerFilter()
        }
    }, [props.updateGarageActivation])

    const triggerFilter=()=>{
        let filter = {}
        if (filterApplied) {
            if (filterParamForApiCall.district) {
                filter['district'] = filterParamForApiCall.district.split("-").join(" ")
            }
            if (filterParamForApiCall.upazila) {
                filter['upazila'] = filterParamForApiCall.upazila.split("-").join(" ")
            }
            if (filterParamForApiCall.borrower) {
                filter['garage_guid'] = filterParamForApiCall.borrower
            }

            if (filterParamForApiCall.branch_guid) {
                filter['branch_guid'] = filterParamForApiCall.branch_guid
            }
        }
        COLLECT_GARAGE_LIST(filter)
    }


    useEffect(() => {
        if (Array.isArray(props.organisations)) {
            setOrgsList(
                props.organisations.map((item) => {
                    return {
                        ...item,
                        value: item.pk,
                        label: item.name
                    }
                })
            )
        }
    }, [props.organisations])

    useEffect(() => {
        if (props.statusUpdateFailed) {
            setShowInProgressModal(false)
            setGarageWhoseStatusIsBeingUpdated(false)
            setBatteryWisePayment(false)
            setShowButtonsRelatedToSelection(false)
            setGarageWhoseStatusIsBeingUpdated('')
            setGarageNewStatusForUpdate('')
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            let filter = {}
            if (filterApplied) {
                if (filterParamForApiCall.district) {
                    filter['district'] = filterParamForApiCall.district.split("-").join(" ")
                }
                if (filterParamForApiCall.upazila) {
                    filter['upazila'] = filterParamForApiCall.upazila.split("-").join(" ")
                }
                if (filterParamForApiCall.borrower) {
                    filter['garage_guid'] = filterParamForApiCall.borrower
                }
            }
            COLLECT_GARAGE_LIST(filter)
        }
    }, [props.statusUpdateFailed])

    const renderInProgressModal = () => {
        let loadingText = 'Updating status of ' + garageWhoseStatusIsBeingUpdated
        if (garageDeactivationStatusIsBeingUpdated) {
            loadingText = 'Updating deactivation logic of ' + garageWhoseStatusIsBeingUpdated
        }
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={false}
                footer={false}
                centered={true}
                hideCrossButton={true}
                modal={showInProgressModal}>
                <LoadingSpinner loadingSubText={loadingText} language={language} showTitle={false} size={'md'}/>
            </GenericModal>
        </>
    }
    // ----- End: Section related to garage status change ----- //


    // ----- Begin: Section related to filter process ---- //
    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            // let filter = {page: page}
            let filter = {}
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            if (filterParamForApiCall.district) {
                filter['district'] = filterParamForApiCall.district.split("-").join(" ")
            }
            if (filterParamForApiCall.upazila) {
                filter['upazila'] = filterParamForApiCall.upazila.split("-").join(" ")
            }
            if (filterParamForApiCall.borrower) {
                filter['garage_guid'] = filterParamForApiCall.borrower
            }

            if (filterParamForApiCall.branch_guid) {
                filter['branch_guid'] = filterParamForApiCall.branch_guid
            }
            COLLECT_GARAGE_LIST(filter)
            setFilterApplied(Object.keys(filter).length > 0)
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);

    const filterTheList = (data) => {
        setPage(1)
        // let searchText = 'page=1'
        let searchText = ''
        if (district) {
            searchText += '&district=' + district.label.split(" ").join("-")
        }
        if (upazila) {
            searchText += '&upazila=' + upazila.label.split(" ").join("-")
        }
        if (borrower) {
            searchText += '&borrower=' + borrower.value
        }

        if (branch) {
            searchText += '&branch_guid=' + branch.value
        }
        setShowButtonsRelatedToSelection(false)
        props.history.push({
            search: searchText
        })
    }

    const resetFilter = () => {
        resetFilterValues()
        clearErrors()
        if (filterApplied) {
            // Resetting
            setPage(1)
            setShowButtonsRelatedToSelection(false)
            props.history.push({
                search: ''
            })
        }
    }

    const resetFilterValues = () => {
        setValue('district', '')
        setDistrict('')
        setDistrictFromUrl('')

        setValue('upazila', '')
        setUpazila('')
        setUpazilaFromUrl('')
        setBranch('')
        setBranchFromUrl('')
        setValue('branch_guid', '')
        setValue('borrower', '')
        setBorrower('')
        setBorrowerFromUrl('')
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (props.upazilas) {
            const upazilas = props.upazilas;
            if (upazilas.length > -1) {
                let upazilaFromResponse = upazilas.map((upazila) => {
                    return {
                        ...upazila,
                        label: upazila.name,
                        value: upazila.id
                    }
                })
                let upazilaToSave = []
                if (districtFromUrl && district) {
                    upazilaToSave = upazilaFromResponse.filter(upazila => upazila.district_id === district.id)
                } else {
                    upazilaToSave = upazilaFromResponse
                }
                setUpazilas(upazilaToSave)
                if (upazilaFromUrl) {
                    let selectedUpazila = upazilaToSave.filter(upazila => upazilaFromUrl === upazila.name)[0]
                    if (selectedUpazila) {
                        selectedUpazila['label'] = upazila.name
                        selectedUpazila['value'] = upazila.id
                        setValue('upazila', selectedUpazila)
                        setUpazila(selectedUpazila)
                    }
                }
            }
        }
    }, [props.upazilas, districtFromUrl])

    useEffect(() => {
        if (props.districts) {
            const districts = props.districts;
            if (districts.length > -1) {
                setDistricts(districts.map((district) => {
                    if (districtFromUrl && districtFromUrl === district.name) {
                        let selectedDistrict = district
                        selectedDistrict['label'] = district.name
                        selectedDistrict['value'] = district.id
                        setValue('district', selectedDistrict)
                        setDistrict(selectedDistrict)
                        COLLECT_UPAZILAS_FOR_ALL(district.id)
                    }
                    return {
                        ...district,
                        label: district.name,
                        value: district.id
                    }
                }))
            }
        }
    }, [props.districts])

    useEffect(() => {
        if (props.borrowers && props.borrowers.length > -1) {
            const borrowers = props.borrowers;
            let borrowersListForSelection = []
            for (let i=0; i<borrowers.length; i++) {
                let garageName = borrowers[i].garage_name
                if (garages && garages.length > 0) {
                    garageName = garages.filter(garage => garage.pk === borrowers[i].garage_guids[0])[0]?.name || garageName
                }
                if (garageName) {
                    let label = garageName + ' (' + borrowers[i].name + ')'

                    if (borrowerFromUrl && borrowerFromUrl === borrowers[i].garage_guids[0]) {
                        let selectedBorrower = borrowers[i]
                        selectedBorrower['label'] = label
                        selectedBorrower['value'] = borrowers[i].garage_guids[0]
                        setValue('borrower', selectedBorrower)
                        setBorrower(selectedBorrower)
                    }
                    borrowersListForSelection.push({
                        ...borrowers[i],
                        label: label,
                        value: borrowers[i].garage_guids[0]
                    })
                }
            }
            setBorrowers(borrowersListForSelection)
        }
    }, [props.borrowers, garages])

    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            setGarages(garages.map((garage) => {
                return {
                    ...garage
                }
            }))
        }
    }, [props.garages])
    // ----- End: Section related to filter process ---- //

    useEffect(() => {
        if (props.branches && Array.isArray(props.branches)) {
            setBranches(props.branches.map((item) => {
                if (branchFromUrl && branchFromUrl === item.pk) {
                    let selectedBranch = item
                    selectedBranch['label'] = item.name
                    selectedBranch['value'] = item.id
                    setValue('branch_guid', selectedBranch)
                    setBranch(selectedBranch)
                }
                return {
                    ...item,
                    value: item.pk,
                    label: item.name
                }
            }))


        }
    }, [props.branches])


    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page', 'district', 'upazila', 'borrower', 'branch_guid']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > -1) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (!isNullorUndefined(initialfilterParam)) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.branch_guid) {
                setBranchFromUrl(initialfilterParam.branch_guid)
            }
            if (initialfilterParam.district) {
                let districtNameFromUrl = initialfilterParam.district.split("-").join(" ")
                setDistrictFromUrl(districtNameFromUrl)
            }
            if (initialfilterParam.upazila) {
                let upazilaNameFromUrl = initialfilterParam.upazila.split("-").join(" ")
                setUpazilaFromUrl(upazilaNameFromUrl)
            }
            if (initialfilterParam.borrower) {
                setBorrowerFromUrl(initialfilterParam.borrower)
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])
    // ----- End: Section related to shareable link/handling query params ---- //


    // ----- Begin: Section related to export data ---- //
    const exportData = () => {
        let presentTime = moment()
        let borrowersLength = garages.length
        let totalBorrowerText = language === 'EN'? "Total Borrowers: " + borrowersLength:"সর্বমোট ঋণগ্রহীতা: " + toBengaliNumber(borrowersLength)
        let exportText = language === 'EN'? "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let excelRows = [
            [pageTitle, "", "", "", "", "", "", "", "", ""],
            [totalBorrowerText, "", "", "", "", "", "", "", "", ""],
            [exportText, "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", ""],
            ["", "", "", "", "", "", "", "", "", ""],
            [column1, "Garage", column2, column3, column4, column5, column6, column7, column8, "Address"]
        ]
        if (filterApplied) {
            if (filterParamForApiCall.borrower && borrower) {
                let text = "Borrower: " + borrower.label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (filterParamForApiCall.upazila && upazila) {
                let text = "Upazila: " + filterParamForApiCall.upazila
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (filterParamForApiCall.district && district) {
                let text = "District: " + filterParamForApiCall.district
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
            if (filterParamForApiCall.branch_guid && branch) {
                let text = "Branch: " + branch.label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
            }
        }
        for (let i=0; i<garages.length; i++) {
            let due = garages[i].payable_total - garages[i].deposit_total
            let platformFeeDeductible = ((garages[i].payable_total * 3) / 100).toFixed(2)
            let receivable = ((garages[i].payable_total * 97) / 100).toFixed(2)
            excelRows.push(
                [
                    garages[i].garage_owner,
                    garages[i].name,
                    garages[i].total_number_of_devices,
                    new Intl.NumberFormat('en-IN').format(garages[i].payable_total.toFixed(2)),
                    new Intl.NumberFormat('en-IN').format(garages[i].deposit_total.toFixed(2)),
                    due > 0? new Intl.NumberFormat('en-IN').format(due.toFixed(2)):0,
                    new Intl.NumberFormat('en-IN').format(platformFeeDeductible),
                    new Intl.NumberFormat('en-IN').format(receivable),
                    garages[i].status,
                    garages[i].upazila && garages[i].district? garages[i].upazila + ',' + garages[i].district:
                        garages[i].district? garages[i].district:garages[i].upazila? garages[i].upazila:'-'
                ])
        }
        let noDataAvailableText =  language === 'EN'? "No data available":"কোন তথ্য নেই"
        garages.length > 0? excelRows.push(
            ["", "", "", "", "", "", "", ""]
        ):excelRows.push(["", "", "", noDataAvailableText, "", "", "", ""])
        let fileName = 'borrowers_'
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }
    // ----- End: Section related to export data ---- //


    // ----- Begin: Section related to pagination ---- //
    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (filterParamForApiCall.district) {
                searchText += '&district=' + filterParamForApiCall.district
            }
            if (filterParamForApiCall.upazila) {
                searchText += '&upazila=' + filterParamForApiCall.upazila
            }
            if (filterParamForApiCall.borrower) {
                searchText += '&borrower=' + filterParamForApiCall.borrower
            }
            props.history.push({
                search: searchText
            })
        } else {
            props.history.push({
                search: searchText
            })
        }
    }
    // ----- End: Section related to pagination ---- //


    // ----- Begin: Section related to Garage details modal ---- //
    const showGarageDetails = (data) => {
        setGarageName(data.name)
        COLLECT_GARAGE_DETAILS({garage_guid: data.pk})
        setShowGarageDetailsModal(true)
    }

    const hideGarageDetailsModal = () => {
        setShowGarageDetailsModal(false)
    }

    useEffect(() => {
        if (props.garageDetails) {
            let garageDetails = props.garageDetails
            setGarageID(garageDetails.garage_id)
            setRegisteredOn(moment(garageDetails.sk).format("MMM DD, YYYY"))
            setGarageUpazila(garageDetails.upazila)
            setGarageDistrict(garageDetails.district)
            setGarageOwnerName(garageDetails.garage_owner)
            setGarageOwnerPhoneNumber(garageDetails.garage_phone_number)
            setGarageOperatorName('')
            setGarageOperatorPhoneNumber('')
        }
    }, [props.garageDetails])

    const renderGarageDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideGarageDetailsModal}
                centered={true}
                modal={showGarageDetailsModal}
                title={<h1><strong>{garageName}</strong></h1>}>
                {!props.garageDetailsCollectionInProgress?
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <ListGroup variant={"flush"}>
                                {garageID? <>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}><strong>Garage ID</strong></small><br/>
                                        <h5><strong>{garageID}</strong></h5>
                                    </ListGroup.Item>
                                </>:null}
                                <ListGroup.Item className={"border-bottom-0"}>
                                    <small className={'text-muted'}><strong>Registered on</strong></small><br/>
                                    <h4><strong>{registeredOn}</strong></h4>
                                </ListGroup.Item>
                                {garageUpazila || garageDistrict?
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}><strong>Address</strong></small><br/>
                                        {garageUpazila && garageDistrict? <span>{garageUpazila}, {garageDistrict}</span>:
                                            garageDistrict? <span>{garageDistrict}</span>:<span>{garageUpazila}</span>}
                                    </ListGroup.Item>:null}
                            </ListGroup>
                        </div>
                        <div className={'col-md-6'}>
                            <ListGroup variant={"flush"}>
                                <ListGroup.Item className={"border-bottom-0"}>
                                    <small className={'text-muted'}><strong>Garage owner</strong></small><br/>
                                    <h5><strong>{garageOwnerName}</strong></h5>
                                    <h5><strong>{garageOwnerPhoneNumber}</strong></h5>
                                </ListGroup.Item>
                            </ListGroup>
                            {garageOperatorName || garageOperatorPhoneNumber?
                                <ListGroup variant={"flush"}>
                                    <ListGroup.Item className={"border-bottom-0"}>
                                        <small className={'text-muted'}><strong>Garage operator</strong></small><br/>
                                        {garageOperatorName? <h4><strong>{garageOperatorName}</strong></h4>:null}
                                        {garageOperatorPhoneNumber? <h4><strong>{garageOperatorPhoneNumber}</strong></h4>:null}
                                    </ListGroup.Item>
                                </ListGroup>:null}
                        </div>
                    </div>:<LoadingSpinner
                        loadingSubText={language === 'EN'? 'Generating garage details..': 'গ্যারেজের বিস্তারিত তথ্য প্রস্তুত হচ্ছে..'}
                        language={language}/>}
            </GenericModal>
        </>
    }
    // ----- End: Section related to Garage details modal ---- //

    const closeGarageStatusChangeModal = () => {
        setShowGarageStatusChangeModal(false)
        setKeyForBorrowerTable(keyForBorrowerTable + 1)
    }

    const submitGarageStatusAction=(data)=>{

        // setShowInProgressModal(true)
        let status = currentRowData.status === 'active'? 'inactive':'active'
        setGarageNewStatusForUpdate(status)
        let payload = {
            garage_guid: currentRowData.pk,
            status: status,
            reason: data.reason.value,
            authorized_by_org: data.authorized_by_org.value,
            action_type: currentRowData.status==='active'? "garage_deactivation": "garage_activation",
            remarks: data.remarks
        }
        UPDATE_GARAGE_ACTIVATION_STATUS(payload)
    }

    const deactivationReasons = [{value: "Loan Default", label: "Loan Default"},{value: "Late Payments", label: "Late Payments"},{value: "Others", label: "Others"}]
    const activationReasons = [{value: "Loan Repayments", label: "Loan Repayments"},{value: "Due Paid", label: "Due Paid"},{value: "Others", label: "Others"}]


    const renderGarageStatusChangeModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                hideModal={closeGarageStatusChangeModal}
                modal={showgarageStatusChangeModal}
                size={"lg"}
                title={<>{currentRowData?.status==='active'?"Deactivating": "Activating"} <strong>{garageWhoseStatusIsBeingUpdated}</strong></>}>
                <Form onSubmit={handleSubmit(submitGarageStatusAction)}>
                    <div className={'row g-3'}>
                        <div className={"col-md-6"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Reason <span className="required text-danger">*</span></Form.Label>
                                </div>

                                <Controller
                                    control={control}
                                    name={"reason"}
                                    rules={{required: "Required"}}
                                    render={({onChange, onBlur, value, name, ref},
                                             {invalid, isTouched, isDirty}) => (
                                        <Select
                                            name={`reason`}
                                            placeholder={language === 'EN' ? 'Select a reason' : 'গ্যারেজ বাছাই করুন'}
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={props.garageStatusIsBeingUpdated}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={currentRowData?.status==='active'? deactivationReasons:activationReasons }
                                            isSearchable={true}
                                            onChange={(selected, {action}) => {
                                                onChange(selected)

                                            }}
                                        />
                                    )}
                                />
                                {errors.reason && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reason.message}</div>}

                            </Form.Group>
                        </div>

                        <div className={"col-md-6"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Authorized By <span className="required text-danger">*</span></Form.Label>
                                </div>

                                <Controller
                                    control={control}
                                    name={"authorized_by_org"}
                                    rules={{required: "Required"}}
                                    render={({onChange, onBlur, value, name, ref},
                                             {invalid, isTouched, isDirty}) => (
                                        <Select
                                            name={name}
                                            placeholder={language === 'EN' ? 'Select an Organization' : 'গ্যারেজ বাছাই করুন'}
                                            classNamePrefix="react-select-sol-style"
                                            isDisabled={props.garageStatusIsBeingUpdated}
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={orgsList}
                                            isSearchable={true}
                                            onChange={(selected, {action}) => {
                                                onChange(selected)

                                            }}
                                        />
                                    )}
                                />
                                {errors.authorized_by_org && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.authorized_by_org.message}</div>}

                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-3'}>
                        <div className={"col-md-12"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Remarks <span className="required text-danger">*</span></Form.Label>
                                </div>

                                <SolTextArea
                                    name={"remarks"}
                                    rows={"3"}
                                    placeholder={"Provide remarks..."}
                                    autoComplete={"off"}
                                    ref={register({
                                        required: "Required"
                                    })}
                                />
                                {errors.remarks && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.remarks.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Button variant={currentRowData?.status==='active'? "danger":"warning"} size={"md"}
                                    type="submit" disabled={props.garageStatusIsBeingUpdated} className={'ml-3 float-right'}>
                                {props.garageStatusIsBeingUpdated ? <Spinner animation="border" size={ 'sm'}
                                                                             variant="secondary"/>:''} {currentRowData?.status==='active'?"Deactivate":"Activate"}
                            </Button>
                            <Button variant="outline-dark" size={'md'} disabled={props.garageStatusIsBeingUpdated}
                                    onClick={closeGarageStatusChangeModal} className={'float-right'}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Form>
            </GenericModal>
        </>
    }


    // ----- Begin: Section related to provided batteries information modal ---- //
    const showProvidedBatteryDetails = (data) => {
        setNumberOfSmartBatteriesProvided(data.total_number_of_devices)
        setRequiredInfoToCollectSmartBatteriesInfo(data)
        setShowProvidedBatteryInfoModal(true)
    }

    const hideProvidedBatteryDetailsModal = () => {
        setShowProvidedBatteryInfoModal(false)
    }

    const renderProvidedBatteryDetailsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideProvidedBatteryDetailsModal}
                centered={true}
                modal={showProvidedBatteryInfoModal}
                title={<h1><strong>{numberOfSmartBatteriesProvided} Smart Batteries Provided</strong></h1>}>
                <ProvidedSmartBatteriesInfo data={requiredInfoToCollectSmartBatteriesInfo}/>
            </GenericModal>
        </>
    }
    // ----- End: Section related to Garage details modal ---- //

    // ----- Begin: Section related to transactions modal ---- //
    const showTransactions = () => {
        setRequiredInfoToCollectTransactions(selectedBorrower[0])
        setShowTransactionsModal(true)
    }

    const hideTransactionsModal = () => {
        setShowTransactionsModal(false)
        if (dueLimitIsUpdated) {
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            let filter = {}
            if (filterApplied) {
                if (filterParamForApiCall.district) {
                    filter['district'] = filterParamForApiCall.district.split("-").join(" ")
                }
                if (filterParamForApiCall.upazila) {
                    filter['upazila'] = filterParamForApiCall.upazila.split("-").join(" ")
                }
                if (filterParamForApiCall.borrower) {
                    filter['garage_guid'] = filterParamForApiCall.borrower
                }
            }
            COLLECT_GARAGE_LIST(filter)
            setShowButtonsRelatedToSelection(false)
            setDueLimitIsUpdated(false)
        }
    }

    const dueLimitUpdateTracker = (updated) => {
        if (updated) {
            setDueLimitIsUpdated(true)
        }
    }

    const renderTransactionsModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideTransactionsModal}
                centered={true}
                modal={showTransactionsModal}
                title={<h1><strong>Transactions</strong></h1>}>
                <TransactionsOfAGarage data={requiredInfoToCollectTransactions}
                                       dueLimitUpdateTracker={dueLimitUpdateTracker}
                                       from={'transactionModal'}/>
            </GenericModal>
        </>
    }
    // ----- End: Section related to transactions modal ---- //

    // ----- Begin: Section related to activities modal ---- //
    const showActivities = () => {
        setShowActivitiesModal(true)
    }

    const hideActivitiesModal = () => {
        setShowActivitiesModal(false)
        if (dueLimitIsUpdated) {
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            let filter = {}
            if (filterApplied) {
                if (filterParamForApiCall.district) {
                    filter['district'] = filterParamForApiCall.district.split("-").join(" ")
                }
                if (filterParamForApiCall.upazila) {
                    filter['upazila'] = filterParamForApiCall.upazila.split("-").join(" ")
                }
                if (filterParamForApiCall.borrower) {
                    filter['garage_guid'] = filterParamForApiCall.borrower
                }
            }
            COLLECT_GARAGE_LIST(filter)
            setShowButtonsRelatedToSelection(false)
            setDueLimitIsUpdated(false)
        }
    }

    const renderActivitiesModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideActivitiesModal}
                centered={true}
                modal={showActivitiesModal}
                title={<h1><strong>Transactions</strong></h1>}>
                <ActivitiesOfAGarage data={selectedBorrower}
                                     dueLimitUpdateTracker={dueLimitUpdateTracker}
                                     from={'activitiesModal'}/>
            </GenericModal>
        </>
    }
    // ----- End: Section related to transactions modal ---- //


    // ----- Begin: Section related to due limit update ---- //
    const updateDueLimit = () => {
        setShowDueLimitUpdateModal(true)
    }

    const hideDueLimitModal = () => {
        setShowDueLimitUpdateModal(false)
        if (dueLimitIsUpdated) {
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            let filter = {}
            if (filterApplied) {
                if (filterParamForApiCall.district) {
                    filter['district'] = filterParamForApiCall.district.split("-").join(" ")
                }
                if (filterParamForApiCall.upazila) {
                    filter['upazila'] = filterParamForApiCall.upazila.split("-").join(" ")
                }
                if (filterParamForApiCall.borrower) {
                    filter['garage_guid'] = filterParamForApiCall.borrower
                }
            }
            COLLECT_GARAGE_LIST(filter)
            setShowButtonsRelatedToSelection(false)
            setDueLimitIsUpdated(false)
        }
    }

    const updateDueLimitRepresentation = (data) => {
        dueLimitUpdateTracker(true)
    }

    const renderDueLimitUpdateModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideDueLimitModal}
                centered={true}
                modal={showDueLimitUpdateModal}
                title={<h1><strong>Edit Due Limit</strong></h1>}>
                <UpdateDueLimitOfAGarage data={selectedBorrower}
                                         updateDueLimitRepresentation={updateDueLimitRepresentation}
                                         from={'directDueLimitEdit'}/>
            </GenericModal>
        </>
    }
    // ----- End: Section related to due limit update ---- //


    // ----- Begin: Section related to cash payment ---- //
    const manualCashPaymentEvent = () => {
        if (selectedBorrower) {
            setTitleOfManualCashPaymentModal(selectedBorrower[0].garage_owner + ', ' + selectedBorrower[0].name)
        }
        setShowManualCashPaymentModal(true)
    }

    const updateManualCashPaymentModalTitle = (newTitle) => {
        setTitleOfManualCashPaymentModal(newTitle)
    }

    const hideManualCashPaymentModal = () => {
        setShowManualCashPaymentModal(false)
    }

    const handleCashPayment = (data) => {
        MANUAL_CASH_PAYMENT(data)
    }

    const renderManualCashPaymentModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideManualCashPaymentModal}
                modal={showManualCashPaymentModal}
                title={<h1><strong>{titleOfManualCashPaymentModal}</strong></h1>}>
                <ManualCashPayment data={selectedBorrower} updateTitle={updateManualCashPaymentModalTitle}
                                   handleCashPayment={handleCashPayment} cancelProcess={hideManualCashPaymentModal}/>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.manualCashPaymentSaved === requestCycle.success) {
            setShowManualCashPaymentModal(false)
            setSelectedBorrower(undefined)
            setShowButtonsRelatedToSelection(false)
            // TODO: Following can be taken into a function as it has been reused in the export API call also
            let filter = {}
            if (filterApplied) {
                if (filterParamForApiCall.district) {
                    filter['district'] = filterParamForApiCall.district.split("-").join(" ")
                }
                if (filterParamForApiCall.upazila) {
                    filter['upazila'] = filterParamForApiCall.upazila.split("-").join(" ")
                }
                if (filterParamForApiCall.borrower) {
                    filter['garage_guid'] = filterParamForApiCall.borrower
                }
            }
            COLLECT_GARAGE_LIST(filter)
        }
    }, [props.manualCashPaymentSaved])

    useEffect(() => {
        if (props.successMessageForManualCashPaymentProcess !== undefined) {
            showNotifications('success', props.successMessageForManualCashPaymentProcess)
        } else if (props.errorMessageForManualCashPaymentProcess !== undefined) {
            showNotifications('error', props.errorMessageForManualCashPaymentProcess)
        }
    }, [props.successMessageForManualCashPaymentProcess, props.errorMessageForManualCashPaymentProcess])
    // ----- End: Section related to cash payment ---- //


    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    <IconButton classes={classes} onClick={exportData}
                                                disabled={props.listLoading || props.isLoading || props.iconLoading}>
                                        {props.iconLoading? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/> :
                                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                                 alt="Download"/>}
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form onSubmit={handleSubmit(filterTheList)}>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-3'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'District':'জেলা'}</Form.Label>
                                                </div>
                                                <Controller
                                                    control={control}
                                                    name={"district"}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={language === 'EN'? 'Select District':'জেলা বাছাই করুন'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.collectingDistricts}
                                                            isLoading={props.collectingDistricts}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={districts}
                                                            isSearchable={true}
                                                            onChange={(selected, {action}) => {
                                                                if (action === "clear") {
                                                                    setDistrict("")
                                                                    setValue("upazila", "")
                                                                    setUpazila("")
                                                                    COLLECT_UPAZILAS_FOR_ALL("all")
                                                                }
                                                                onChange(selected)
                                                                if (selected) {
                                                                    setDistrict(selected)
                                                                    setValue("upazila", "")
                                                                    setUpazila("")
                                                                    COLLECT_UPAZILAS_FOR_ALL(selected.id)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Sub District / Upazila':'উপজেলা'}</Form.Label>
                                                </div>
                                                <Controller
                                                    control={control}
                                                    name={"upazila"}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={language === 'EN'? 'Select Sub District':'উপজেলা বাছাই করুন'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.collectingUpazilas}
                                                            isLoading={props.collectingUpazilas}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={upazilas}
                                                            isSearchable={true}
                                                            onChange={(selected, {action}) => {
                                                                if (action === "clear") {
                                                                    setUpazila("")
                                                                }
                                                                onChange(selected)
                                                                if (selected) {
                                                                    setUpazila(selected)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Garage':'গ্যারেজ'}</Form.Label>
                                                </div>
                                                <Controller
                                                    control={control}
                                                    name={"borrower"}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={language === 'EN'? 'Select Garage':'গ্যারেজ বাছাই করুন'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.borrowersCollectionInProgress}
                                                            isLoading={props.borrowersCollectionInProgress}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={borrowers}
                                                            isSearchable={true}
                                                            onChange={(selected, {action}) => {
                                                                if (action === "clear") {
                                                                    setBorrower("")
                                                                }
                                                                onChange(selected)
                                                                if (selected) {
                                                                    setBorrower(selected)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-3'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN' ? 'Branch' : 'গ্যারেজ'}</Form.Label>
                                                </div>
                                                <Controller
                                                    control={control}
                                                    name={"branch_guid"}
                                                    render={({ onChange, onBlur, value, name, ref },
                                                             { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={language === 'EN' ? 'Select Branch' : 'গ্যারেজ বাছাই করুন'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.borrowersCollectionInProgress}
                                                            isLoading={props.borrowersCollectionInProgress}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={branches}
                                                            isSearchable={true}
                                                            onChange={(selected, { action }) => {
                                                                if (action === "clear") {
                                                                    setBranch("")
                                                                }
                                                                onChange(selected)
                                                                if (selected) {
                                                                    setBranch(selected)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'sm'} type="submit"
                                                    disabled={props.garageCollectionInProgress && filterApplied}>
                                                {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                            </Button>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}} className={'ml-3'}>
                                                {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                {showButtonsRelatedToSelection? <>
                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Stack direction={'row'} justifyContent="flex-start" spacing={1}>
                                {canSaveManualPayment && showManualCashPaymentButton? <>
                                    <Button variant="warning" size={'sm'} type="button"
                                            onClick={manualCashPaymentEvent}>
                                        Recharge via Cash Payment
                                    </Button>
                                </>:null}
                                {canSeeBorrowerActivity && showActivitiesButton? <>
                                    <Button variant="warning" size={'sm'} type="button"
                                            onClick={showActivities}>
                                        See Transactions
                                    </Button>
                                </>:null}
                                {canSeeTransactions && showTransactionsButton? <>
                                    <Button variant="warning" size={'sm'} type="button"
                                            onClick={showTransactions}>
                                        See Transactions
                                    </Button>
                                </>:null}
                                {canEdit? <>
                                    <Button variant="warning" size={'sm'} type="button"
                                            onClick={updateDueLimit}>
                                        Edit Due Limit
                                    </Button>
                                </>:null}
                            </Stack>
                        </div>
                    </div>
                </>:null}
                <div className={'row'} key={keyForBorrowerTable}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.garageCollectionInProgress? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ঋণগ্রহীতা নেই'}
                                columns={garageListColumns}
                                data={garages}
                                showToolbar={false}
                                asyncPagination={false}
                                isLoading={props.tableLoading}
                                pagination={true}
                                pageSize={10}
                                // Selection related
                                selection={enableBorrowerSelection}
                                showSelectAllCheckbox={false}
                                onSelectionChange={onSelection}
                                // Action related
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={ canEdit? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }}>
                                                    Edit
                                                </Button>
                                            },
                                            onClick: (e, row) => goToEditBorrower(row)
                                        })
                                    }
                                ]:[]}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting borrower list ..': 'ঋণগ্রহীতার তালিকা সংগ্রহ করা হচ্ছে ..'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {renderGarageDetailsModal()}
                {renderProvidedBatteryDetailsModal()}
                {renderTransactionsModal()}
                {renderActivitiesModal()}
                {renderDueLimitUpdateModal()}
                {renderInProgressModal()}
                {renderManualCashPaymentModal()}
                {renderGarageStatusChangeModal()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

Borrowers.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        isLoading: state.borrowersReducer.isLoading,
        iconLoading: state.borrowersReducer.iconLoading,
        listLoading: state.borrowersReducer.listLoading,
        districts: state.borrowersReducer.districts,
        upazilas: state.borrowersReducer.upazilas,
        collectingUpazilas: state.borrowersReducer.collectingUpazilas,
        borrowers: state.commonReducer.borrowers,
        branches: state.commonReducer.branches,
        borrowersCollectionInProgress: state.commonReducer.collectingBorrowers,
        garages: state.borrowersReducer.garages,
        garageCollectionInProgress: state.borrowersReducer.garageCollectionInProgress,
        garageDetails: state.borrowersReducer.garageDetails,
        garageDetailsCollectionInProgress: state.borrowersReducer.garageDetailsCollectionInProgress,
        providedSmartBatteries: state.borrowersReducer.providedSmartBatteries,
        smartBatteryInfoCollectionInProgress: state.borrowersReducer.smartBatteryInfoCollectionInProgress,
        updateGarageActivation: state.borrowersReducer.updateGarageActivation,
        garageStatusIsBeingUpdated: state.borrowersReducer.garageStatusIsBeingUpdated,
        statusUpdateFailed: state.borrowersReducer.statusUpdateFailed,
        manualCashPaymentSaved: state.borrowersReducer.manualCashPaymentSaved,
        manualCashPaymentInProgress: state.borrowersReducer.manualCashPaymentInProgress,
        successMessageForManualCashPaymentProcess: state.borrowersReducer.successMessageForManualCashPaymentProcess,
        errorMessageForManualCashPaymentProcess: state.borrowersReducer.errorMessageForManualCashPaymentProcess,
        organisations: state.commonReducer.organisations,
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(Borrowers));