import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils';
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import moment from "moment";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {checkAuthorization} from "../../../auth/authorization";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const RoleList = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [showFilter, setShowFilter] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [page, setPage] = useState(1);
    const [queryParams, setQueryParams] = useState(null)
    const [initialfilterParam, setInitialfilterParam] = useState(null)
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null)
    const [urlIsEdited, setUrlIsEdited] = useState(false)
    const subHeader = useSubheader();
    const [organizations, setOrganizations] = useState(null);
    const [roleName, setRoleName] = useState(null);
    const [orgName, setOrgName] = useState(null);
    const [permittedActions, setPermittedActions] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState(null);
    const [userServiceName, setUserServiceName] = useState(getServiceName('userService'));
    const [roleFeatureName, setRoleFeatureName] = useState(getFeatureName('roleFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));

    const roleListColumns = [
        {
            field: 'name',
            title: language === 'EN'? 'Name':'নাম',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'created_at',
            title: language === 'EN'? 'Created At':'তৈরির সময়',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true
        }
    ]

    const {
        GET_ROLE_LIST,
        GET_ORGANISATIONS
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        if (checkAuthorization(props.authorization, userServiceName, roleFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" type="button" size={'sm'} style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={gotToCreateRole} classes={classes} >
                        {props.language === 'EN'? <i className="fa fa-plus" aria-hidden="true"></i>:null}
                        {props.language === 'EN'? 'Create Role':'ব্যবহারকারীর ধরন তৈরি করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)
        if (!props.history.location.search) {
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
        GET_ORGANISATIONS();
    }, []);


    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            GET_ROLE_LIST({page: page})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //


    useEffect(() => {
        const roleList = props.roleList;
        if (roleList && roleList.length > -1) {
            setRoleList(roleList.map((role, index) => {
                return {
                    ...role,
                    name: role.name,
                    created_at: role.created_at? moment(role.created_at).format("DD MMMM,YYYY hh:mm a"): "No Data Found"
                }
            }))
        }
    }, [props.roleList]);

    useEffect(()=>{
        const organisations = props.organisations
        if (organisations && organisations.length >= 0) {
            setOrganizations(organisations.map((organisation) => {
                return {
                    name: organisation.name,
                    pk: organisation.pk
                }
            }));
        }
    },[props.organisations])

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        props.history.push({
            search: 'page=' + page
        })
    }

    const gotToCreateRole = () => {
        props.history.push('/roles/create')
    }

    const showRoleDetails = (data) => {
        setRoleName(data.name)
        if (organizations) {
            for (let i=0; i<organizations.length; i++) {
                if (organizations[i].pk === data.organization_guid) {
                    setOrgName(organizations[i].name)
                    break
                }
            }
        }
        if (data.permissions.length > 0) {
            let permissions = []
            for (let i=0; i<data.permissions.length; i++) {
                permissions.push(data.permissions[i].action_title)
            }
            setPermittedActions(permissions)
        }
        setShowDetailsModal(true)
    }

    const closeDetailsModal = () => {
        setShowDetailsModal(false)
    }

    const permissionList = (perms) => {
        let permissions = []
        perms.map((permission) => {
            permissions.push(<li>{permission}</li>)
        })
        return <><ul>{permissions}</ul></>
    }

    const detailsModal = () => {
        return (
            <>
                <GenericModal
                    showModalHeader={true}
                    footer={true}
                    footerButtonSize={'sm'}
                    footerCloseButtonText={'Close'}
                    hideModal={closeDetailsModal}
                    centered={true}
                    modal={showDetailsModal}
                    noSubmitButton={true}
                    title={<>Details of the role: <SolTypography.Text
                        primary>{roleName}</SolTypography.Text></>}>
                    <p className={'no-padding'}><strong>Organization:</strong>&nbsp;{orgName? <SolTypography.Text primary>{orgName}</SolTypography.Text>:
                        <>N/A&nbsp;<span className={'text-danger'}>(Failed to collect organization name)</span></>}</p>
                    {permittedActions.length > 0? <>
                        <p><strong>Permissible actions for this role:</strong></p>
                        {permissionList(permittedActions)}
                    </>:<>
                        <p className={'no-padding'}>No permission is available for this role</p>
                    </>}
                </GenericModal>
            </>
        )
    }

    const goToRoleUpdatePage = (data) => {
        props.history.push({
            pathname: '/roles/update/',
            detailsData: data
        })
    }

    return (
        <div>
        <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                        serverError={false} permissionDenied={false} pageTitle={"Roles"}
                        statusCode={props.statusCode}>

            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{language === 'EN'? 'Roles':'ব্যবহারকারীর ধরনের তালিকা'}</b></h1>
                        </Toolbar.Title>
                        <Toolbar.ToolbarContainer>
                            <Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer.Items>
                        </Toolbar.ToolbarContainer>
                    </Toolbar>
                </div>
            </div>
            <hr/>
            <DataTableContainer>
                {props.listLoading !== true ? <DataTable
                    language={language}
                    noDataAvailableMessageInBangla={'কোন ধরন নেই'}
                    columns={roleListColumns}
                    data={roleList}
                    showToolbar={false}
                    asyncPagination={true}
                    isLoading={props.tableLoading}
                    // count={props.roleList?.count}
                    // itemsPerPage={props.roleList?.page_size}
                    // onChangePage={onChangePage}
                    // page={page}
                    pagination={true}
                    pageSize={10}
                    actionColumnIndex={-1}
                    overrideCustomActions={true}
                    actions={checkAuthorization(authorization, userServiceName, roleFeatureName, [updateActionName])? [
                        {
                            position: "row",
                            action: (rowData) => ({
                                icon: () => {
                                    return <Button variant={'info'}
                                                   size={'sm'}
                                                   style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                        Details
                                    </Button>
                                },
                                onClick: (e, row) => showRoleDetails(row)
                            })
                        },
                        {
                            position: "row",
                            action: (rowData) => ({
                                icon: () => {
                                    return <Button variant={'warning'}
                                                   size={'sm'}
                                                   style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                        Update
                                    </Button>
                                },
                                onClick: (e, row) => goToRoleUpdatePage(row)
                            })
                        }
                    ]:[]}
                /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating role list ..': 'ব্যবহারকারীর ধরনের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
            </DataTableContainer>

            {detailsModal()}

        </ContentWrapper>

        <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
    </div>
    );
};

RoleList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.rolesReducer.isLoading,
        tableLoading: state.rolesReducer.tableLoading,
        statusCode: state.rolesReducer.statusCode,
        roleList: state.rolesReducer.roleList,
        listLoading: state.rolesReducer.listLoading,
        organisations: state.rolesReducer.organisations
    }
}

export default connect(mapStateToProps, actions)(withRoles(RoleList));
