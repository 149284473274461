const actionNames = {
    listAction: 'search',
    createAction: 'create',
    updateAction: 'update',
    deleteAction: 'delete',
    uploadAction: 'upload',
    contractInstallmentUpdateAction: 'installment_update',
    contractExtendAction: 'extend_contract',
    contractRescheduleInvoiceAction: 'reschedule_installment_invoice',
    overviewRentedForTodayAction: 'rented_for_today',
    overviewKmTravelledAction: 'km_travelled',
    overviewCo2EmissionAction: 'co2_emission_stat',
    overviewLatePaymentAction: 'late_payment',
    overviewOverdueAction: 'overdue_this_week',
    overviewPerformanceAction: 'payment_performance'
};

export const getActionName = (action) => {
    return actionNames.hasOwnProperty(action)? actionNames[action]: undefined;
};
