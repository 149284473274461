import {Redirect, Switch} from "react-router-dom";
import React from "react";
import RentLogRds from "./views/rentLog/rentLogRDS";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const RentLogRouter2 =(props)=> {

    return (
        <Switch>
            {/* Following url/path is added as it has been used in the automated mail throughout the company*/}
            <Redirect
                exact={"true"}
                from={"/rds"}
                to={"/rds/rent-logs"}
            />

            <SecuredRoute
                path={"/rds/rent-logs"}
                component={RentLogRds}
                service={getServiceName('garageService')}
                feature={getFeatureName('batteryRentFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
