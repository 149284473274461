import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import { useLocation } from "react-router";
import {Button, Form, InputGroup, OverlayTrigger, Spinner, Card, Accordion} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {requestCycle} from "../../utils";
import {rolesReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {makeStyles} from "@material-ui/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import AccordionForFeatureRepresentation from "../../../../components/accordionForShowingFeatureList";
import {useSubheader} from "../../../../../_metronic/layout";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const CreateRole = props => {
    const {control} = useForm();
    const location = useLocation();
    const [language, setLanguage] = useState('EN');
    const [role, setRole] = useState(sessionStorage.getItem('roleName')? sessionStorage.getItem('roleName'):"");
    const [rolePk, setRolePk] = useState(sessionStorage.getItem('rolePrimaryKey'));
    const [org, setOrg] = useState(null);
    const [organisations, setOrganisations] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});
    const subHeader = useSubheader()

    const [featureList, setFeatureList] = useState([]);
    const [featureListForRepresentation, setFeatureListForRepresentation] = useState([]);
    const [selectedActionsByUser, setSelectedActionsByUser] = useState([]);
    const [selectedReferences, setSelectedReferences] = useState([]);
    const [edit, setEdit] = useState(sessionStorage.getItem('edit'));
    const [preSelectedPermissions, setPreSelectedPermission] = useState(sessionStorage.getItem('permissions') ? JSON.parse(sessionStorage.getItem('permissions')):[]);

    const {
        COLLECT_FEATURE_LIST,
        GET_ORGANISATIONS,
        CREATE_ROLE,
        UPDATE_ROLE
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        COLLECT_FEATURE_LIST()
        GET_ORGANISATIONS()
        subHeader.setActionButtons(null)
        if (location.detailsData)
        {
            sessionStorage.setItem('edit', true)
            sessionStorage.setItem('roleName', location.detailsData.name)
            sessionStorage.setItem('rolePrimaryKey', location.detailsData.pk)
            sessionStorage.setItem('org', location.detailsData.organization_guid)
            sessionStorage.setItem('permissions', JSON.stringify(location.detailsData.permissions))
            setRole(location.detailsData.name)
            setRolePk(location.detailsData.pk)
            setPreSelectedPermission(location.detailsData.permissions)
            setEdit(true)
        }


        return ()=>{
            clearSessionStorage()
        }
    }, [])

    useEffect(()=>{
        const organisations = props.organisations
        if (organisations && organisations.length >= 0) {
            setOrganisations(organisations.map((organisation) => {
                if (edit && organisation.pk === sessionStorage.getItem('org'))
                {
                    setOrg({
                        label: organisation.name,
                        value: organisation.pk
                    })
                }
                return {
                    label: organisation.name,
                    value: organisation.pk
                }
            }));
        }
    },[props.organisations])

    useEffect(() => {
        let featureList = props.featureList
        setFeatureList(featureList)
        if (featureList) {
            let featureRows = []
            let thisRowFeatures = []
            let selectedActions = []
            for (let i=0; i<featureList.length; i++) {
                let actions = []
                let selectedActionCount = 0
                let keepAccordionOpen = false
                let accordionEventKey = i + 1
                for (let j=0; j<featureList[i].permissions.length; j++) {
                    let actionIsPreSelected = false
                    if (edit && preSelectedPermissions.length > 0) {
                        for (let k=0; k<preSelectedPermissions.length; k++) {
                            if (preSelectedPermissions[k].feature_name === featureList[i].feature_name &&
                                preSelectedPermissions[k].action_name === featureList[i].permissions[j].action_name) {
                                actionIsPreSelected = true
                                selectedActionCount += 1
                                // Remainder: In case of update, we are only saving preselected Action JSON ("action_json") for submit
                                // While in case of creation, 'selectedActionsByUser' will hold all information related to selected action
                                // At the time of submission, only "action_json" is accessed
                                selectedActions.push({'action_json': preSelectedPermissions[k],
                                    'actionName': featureList[i].permissions[j].action_name + "_" + featureList[i].permissions[j].feature_name})
                                break
                            }
                        }
                    }
                    actions.push({'label': featureList[i].permissions[j].action_title,
                        'featureName': featureList[i].permissions[j].feature_name,
                        'actionName': featureList[i].permissions[j].action_name + "_" + featureList[i].permissions[j].feature_name,
                        'action_json': featureList[i].permissions[j],
                        'selected': actionIsPreSelected})
                }
                if (selectedActionCount > 0) {
                    keepAccordionOpen = true
                }
                if (i === featureList.length - 1) {
                    if (i % 4 === 0) {
                        if (thisRowFeatures.length > 1) {
                            featureRows.push(thisRowFeatures)
                        }
                        featureRows.push([{
                            featureName: featureList[i].feature_title,
                            actions: actions,
                            keepAccordionOpen: keepAccordionOpen,
                            selectedActions: selectedActionCount,
                            accordionEventKey: accordionEventKey
                        }])
                    } else {
                        thisRowFeatures.push({
                            featureName: featureList[i].feature_title,
                            actions: actions,
                            keepAccordionOpen: keepAccordionOpen,
                            selectedActions: selectedActionCount,
                            accordionEventKey: accordionEventKey
                        })
                        featureRows.push(thisRowFeatures)
                    }
                } else if (i % 4 === 0 && i !== 0) {
                    featureRows.push(thisRowFeatures)
                    thisRowFeatures = []
                    thisRowFeatures.push({
                        featureName: featureList[i].feature_title,
                        actions: actions,
                        keepAccordionOpen: keepAccordionOpen,
                        selectedActions: selectedActionCount,
                        accordionEventKey: accordionEventKey
                    })
                } else {
                    thisRowFeatures.push({
                        featureName: featureList[i].feature_title,
                        actions: actions,
                        keepAccordionOpen: keepAccordionOpen,
                        selectedActions: selectedActionCount,
                        accordionEventKey: accordionEventKey
                    })
                }
            }
            setSelectedActionsByUser(selectedActions)
            setFeatureListForRepresentation(featureRows)
        }
    }, [props.featureList])

    const renderRows = (features) => {
        let rows = []
        features.map((row) => {
            rows.push(<div className={'row g-3'}>
                    <div className={'col-md-3 mb-2'}>
                        <AccordionForFeatureRepresentation featureName={row[0].featureName}
                                                           keepAccordionOpen={row[0].keepAccordionOpen}
                                                           accordionEventKey={row[0].accordionEventKey}
                                                           // accordionEventKey={0}
                                                           onCheck={saveCheckedActions}
                                                           actions={row[0].actions}
                                                           selectedActions={row[0].selectedActions}
                                                           language={language}/>
                    </div>
                {row[1]? <div className={'col-md-3 mb-2'}>
                    <AccordionForFeatureRepresentation featureName={row[1].featureName}
                                                       keepAccordionOpen={row[1].keepAccordionOpen}
                                                       accordionEventKey={row[1].accordionEventKey}
                                                       // accordionEventKey={0}
                                                       onCheck={saveCheckedActions}
                                                       actions={row[1].actions}
                                                       selectedActions={row[1].selectedActions}
                                                       language={language}/>
                </div>: null}
                {row[2]? <div className={'col-md-3 mb-2'}>
                    <AccordionForFeatureRepresentation featureName={row[2].featureName}
                                                       keepAccordionOpen={row[2].keepAccordionOpen}
                                                       accordionEventKey={row[2].accordionEventKey}
                                                       // accordionEventKey={0}
                                                       onCheck={saveCheckedActions}
                                                       actions={row[2].actions}
                                                       selectedActions={row[2].selectedActions}
                                                       language={language}/>
                </div>:null}
                {row[3]? <div className={'col-md-3 mb-2'}>
                    <AccordionForFeatureRepresentation featureName={row[3].featureName}
                                                       keepAccordionOpen={row[3].keepAccordionOpen}
                                                       accordionEventKey={row[3].accordionEventKey}
                                                       // accordionEventKey={0}
                                                       onCheck={saveCheckedActions}
                                                       actions={row[3].actions}
                                                       selectedActions={row[3].selectedActions}
                                                       language={language}/>
                </div>:null}
                </div>)
        })
        return <>{rows}</>
    }

    // const saveCheckedActions = (actions, references, isChecked) => {
    const saveCheckedActions = (actions, isChecked) => {
        clearErrorMessages()
        if (isChecked) {
            // Adding action and saving references
            let selectedActions = selectedActionsByUser
            selectedActions = selectedActions.concat(actions)
            setSelectedActionsByUser(selectedActions)
            // let refs = selectedReferences
            // refs = selectedReferences.concat(references)
            // setSelectedReferences(refs)
        } else {
            // Removing action and references
            let selectedActions = selectedActionsByUser
            // let references = selectedReferences
            for (let i=0; i<actions.length; i++) {
                selectedActions = selectedActions.filter(el => el.actionName !== actions[i].actionName)
                // references = selectedReferences.filter(el => el !== references[i])
            }
            setSelectedActionsByUser(selectedActions)
            // setSelectedReferences(references)
        }
    }

    const clearErrorMessages = () => {
        setErrorMessage({})
    }

    const clearSessionStorage = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('permissions')
        sessionStorage.removeItem('org')
        sessionStorage.removeItem('roleName')
        sessionStorage.removeItem('rolePrimaryKey')
    }

    const submitRoleInfo = () => {
        if (!role) {
            setErrorMessage({"role": true})
        } else if (!org) {
            setErrorMessage({"organisation": true})
        } else if (selectedActionsByUser.length === 0) {
            setErrorMessage({'actionSelection': true})
        } else {
            let permissions = []
            for (let i=0; i<selectedActionsByUser.length; i++) {
                permissions.push(selectedActionsByUser[i].action_json)
            }
            let payload = {
                "name": role,
                "organization_guid": org.value,
                "permissions": permissions
            }
            if (edit) {
                if (!rolePk) {
                    if (language === 'EN') {
                        toast.error("Can not update the role! Please contact with admin.")
                    } else {
                        toast.error("সম্পাদনা করা সম্ভব নয়! দয়া করে এডমিনের সাথে যোগাযোগ করুন।")
                    }
                } else {
                    UPDATE_ROLE(rolePk, payload)
                    clearSessionStorage()
                }
            } else {
                CREATE_ROLE(payload)
            }
        }
    }

    // const resetSelection = () => {
    //     if (selectedReferences.length > 1) {
    //         for (let i=0; i<selectedReferences.length; i++) {
    //             selectedReferences[i].checked = false
    //         }
    //         setResetSelectedActions(true)
    //         setSelectedReferences([])
    //         setSelectedActionsByUser([])
    //     }
    // }

    useEffect(()=>{
        if (props.roleCreated === requestCycle.success){
            if (language === 'EN') {
                toast.success("Role is created successfully!")
            } else {
                toast.success("সফলভাবে ধরন তৈরি হয়েছে!")
            }
            props.history.push('/roles/list/');
        } else if (props.roleCreated === requestCycle.failed) {
            if (language === 'EN') {
                showNotifications('error', props.errorMessageRoleCreation)
            } else {
                toast.error("তথ্য সংরক্ষণের চেষ্টা ব্যর্থ্য হয়েছে!")
            }
        }
    },[props.roleCreated])

    useEffect(()=>{
        if (props.roleUpdated === requestCycle.success){
            if (language === 'EN') {
                toast.success("Role is updated successfully!")
            } else {
                toast.success("সফলভাবে সম্পাদনা সম্পন্ন হয়েছে!")
            }
            props.history.goBack()
        } else if (props.roleUpdated === requestCycle.failed) {
            if (language === 'EN') {
                showNotifications('error', props.errorMessageRoleUpdate)
            } else {
                toast.error("সম্পাদনার চেষ্টা ব্যর্থ হয়েছে!")
            }
        }
    },[props.roleUpdated])

    return (
        <ContentWrapper showCardHeader={false} pageTitle={'Register user'} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{!edit? (language === 'EN'? 'Create Role':'ব্যবহারকারীর ধরন তৈরি করুন'):(language === 'EN'? 'Update Role':'ব্যবহারকারীর ধরন সম্পাদনা করুন')}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>
            <hr/>
            {props.featureListCollectionGoingOn? <LoadingSpinner language={language} loadingSubText={language === "EN"? "Collecting feature list":"ফিচারের তালিকা সংগ্রহ করা হচ্ছে"}/>:
                <>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <Form>
                                <div className={'row g-3'}>
                                    <div className={"col-md-6"}>
                                        <Form.Group>
                                            <Form.Label>{language === 'EN'? 'Role Name':'ধরনের নাম'}<span className="required text-danger">*</span></Form.Label>
                                            <SolInput
                                                type="text"
                                                name={"role"}
                                                placeholder={language === 'EN'? "Type name of the role":"ধরনের নাম লিখুন"}
                                                autoComplete={"off"}
                                                value={role}
                                                onChange={(event) => {
                                                    clearErrorMessages()
                                                    if (event.target.value) {
                                                        setRole(event.target.value)
                                                    } else {
                                                        setRole("")
                                                    }
                                                }}
                                            />
                                            {errorMessage.role? <span className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please provide a role name!':'দয়া করে ধরনের নাম লিখুন!'}</span>:null}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row g-3'}>
                                    <div className={'col-md-6'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? 'Organisation':'প্রতিষ্ঠান'}<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <Select
                                                name={`organisation`}
                                                placeholder={language === 'EN'? 'Select an Organisation':'একটি প্রতিষ্ঠান বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.organisationListLoading}
                                                isLoading={props.organisationListLoading}
                                                maxMenuHeight={200}
                                                value={org}
                                                isClearable={true}
                                                control={control}
                                                options={organisations}
                                                isSearchable={true}
                                                onChange={(selected) => {
                                                    setOrg(selected)
                                                    clearErrorMessages()
                                                }}
                                            />
                                            {errorMessage.organisation? <span className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please select an organisation!':'দয়া করে একটি প্রতিষ্ঠান বাছাই করুন!'}</span>:null}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row g-3 mb-4'}>
                                    <div className={"col-md-6"}>
                                        <span className={"text-left"}><b>{language === 'EN'? 'Select features and actions that will be accessible by this role':'ব্যবহারকারীর এই ধরনটি যে যে ফিচার দেখতে পারবে এবং একশন নিতে পারবে তা বাছাই করুন'}:</b></span>
                                        {errorMessage.actionSelection? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please select at least one action!':'দয়া করে অন্তত একটি একশন বাছাই করুন!'}</div>:null}
                                    </div>
                                </div>
                                {renderRows(featureListForRepresentation)}
                                <div className={'row g-3 mt-1'}>
                                    <div className={"col-md-12"}>
                                        <Button variant="warning" type="button" size={'sm'} disabled={props.roleInfoSubmitting}
                                                onClick={()=>{
                                                    submitRoleInfo()
                                                }}>
                                            {props.roleInfoSubmitting? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null}
                                            <i className='fa fa-check' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Save':'সংরক্ষণ করুন'}
                                        </Button>
                                        {/*<Button variant="danger" type="button" size={'sm'} className={"ml-3"}*/}
                                        {/*        disabled={false} onClick={()=>{resetSelection()}}>*/}
                                        {/*    Reset*/}
                                        {/*</Button>*/}
                                        <Button variant="dark" size={'sm'} className={"ml-3"}
                                                onClick={()=>{
                                                    clearSessionStorage()
                                                    props.history.goBack()
                                                }} style={{
                                            backgroundColor: '#8C8C9B',
                                            outline: '#8C8C9B',
                                            border: '#8C8C9B',
                                        }}>
                                            {language === 'EN'? 'Close':'বন্ধ করুন'}&nbsp;<i className='fa fa-times' aria-hidden='true'/>
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </>}
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
        </ContentWrapper>
    );
};

CreateRole.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        language: state.auth.language,
        featureListCollectionGoingOn: state.rolesReducer.featureListCollecting,
        featureList: state.rolesReducer.featureList,
        organisations: state.rolesReducer.organisations,
        organisationListLoading: state.rolesReducer.organisationListLoading,
        roleInfoSubmitting: state.rolesReducer.roleInfoSubmitting,
        roleCreated: state.rolesReducer.roleCreated,
        successMessageRoleCreation: state.rolesReducer.successMessageRoleCreation,
        errorMessageRoleCreation: state.rolesReducer.errorMessageRoleCreation,
        roleUpdated: state.rolesReducer.roleUpdated,
        errorMessageRoleUpdate: state.rolesReducer.errorMessageRoleUpdate
    }
}

export default connect(mapStateToProps, actions) (CreateRole);
