export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_FINANCIERS_REQUEST: 'COLLECT_FINANCIERS_REQUEST',
    COLLECT_FINANCIERS_SUCCESS: 'COLLECT_FINANCIERS_SUCCESS',
    COLLECT_FINANCIERS_FAILURE: 'COLLECT_FINANCIERS_FAILURE',

    COLLECT_LOAN_LIST_REQUEST: 'COLLECT_LOAN_LIST_REQUEST',
    COLLECT_LOAN_LIST_SUCCESS: 'COLLECT_LOAN_LIST_SUCCESS',
    COLLECT_LOAN_LIST_FAILURE: 'COLLECT_LOAN_LIST_FAILURE',

    COLLECT_LOAN_DETAILS_REQUEST: 'COLLECT_LOAN_DETAILS_REQUEST',
    COLLECT_LOAN_DETAILS_SUCCESS: 'COLLECT_LOAN_DETAILS_SUCCESS',
    COLLECT_LOAN_DETAILS_FAILURE: 'COLLECT_LOAN_DETAILS_FAILURE',

    COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_REQUEST: 'COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_REQUEST',
    COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_SUCCESS: 'COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_SUCCESS',
    COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_FAILURE: 'COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_FAILURE',

    COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_REQUEST: 'COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_REQUEST',
    COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_SUCCESS: 'COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_SUCCESS',
    COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_FAILURE: 'COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_FAILURE',

    COLLECT_LOAN_LIST_FOR_EXPORT_REQUEST: 'COLLECT_LOAN_LIST_FOR_EXPORT_REQUEST',
    COLLECT_LOAN_LIST_FOR_EXPORT_SUCCESS: 'COLLECT_LOAN_LIST_FOR_EXPORT_SUCCESS',
    COLLECT_LOAN_LIST_FOR_EXPORT_FAILURE: 'COLLECT_LOAN_LIST_FOR_EXPORT_FAILURE',

    COLLECT_SMART_BATTERY_LIST_REQUEST: 'COLLECT_SMART_BATTERY_LIST_REQUEST',
    COLLECT_SMART_BATTERY_LIST_SUCCESS: 'COLLECT_SMART_BATTERY_LIST_SUCCESS',
    COLLECT_SMART_BATTERY_LIST_FAILURE: 'COLLECT_SMART_BATTERY_LIST_FAILURE',

    COLLECT_SMART_DONGLE_LIST_REQUEST: 'COLLECT_SMART_DONGLE_LIST_REQUEST',
    COLLECT_SMART_DONGLE_LIST_SUCCESS: 'COLLECT_SMART_DONGLE_LIST_SUCCESS',
    COLLECT_SMART_DONGLE_LIST_FAILURE: 'COLLECT_SMART_DONGLE_LIST_FAILURE',

    COLLECT_BORROWER_LIST_REQUEST: 'COLLECT_BORROWER_LIST_REQUEST',
    COLLECT_BORROWER_LIST_SUCCESS: 'COLLECT_BORROWER_LIST_SUCCESS',
    COLLECT_BORROWER_LIST_FAILURE: 'COLLECT_BORROWER_LIST_FAILURE',

    COLLECT_GARAGE_LIST_REQUEST: 'COLLECT_GARAGE_LIST_REQUEST',
    COLLECT_GARAGE_LIST_SUCCESS: 'COLLECT_GARAGE_LIST_SUCCESS',
    COLLECT_GARAGE_LIST_FAILURE: 'COLLECT_GARAGE_LIST_FAILURE',

    COLLECT_DRIVER_LIST_REQUEST: 'COLLECT_DRIVER_LIST_REQUEST',
    COLLECT_DRIVER_LIST_SUCCESS: 'COLLECT_DRIVER_LIST_SUCCESS',
    COLLECT_DRIVER_LIST_FAILURE: 'COLLECT_DRIVER_LIST_FAILURE',

    TERMINATE_LOAN_CONTRACT_REQUEST: 'TERMINATE_LOAN_CONTRACT_REQUEST',
    TERMINATE_LOAN_CONTRACT_SUCCESS: 'TERMINATE_LOAN_CONTRACT_SUCCESS',
    TERMINATE_LOAN_CONTRACT_FAILURE: 'TERMINATE_LOAN_CONTRACT_FAILURE',

    CREATE_LOAN_CONTRACT_REQUEST: 'CREATE_LOAN_CONTRACT_REQUEST',
    CREATE_LOAN_CONTRACT_SUCCESS: 'CREATE_LOAN_CONTRACT_SUCCESS',
    CREATE_LOAN_CONTRACT_FAILURE: 'CREATE_LOAN_CONTRACT_FAILURE',

    RESCHEDULE_THE_INSTALLMENT_REQUEST: 'RESCHEDULE_THE_INSTALLMENT_REQUEST',
    RESCHEDULE_THE_INSTALLMENT_SUCCESS: 'RESCHEDULE_THE_INSTALLMENT_SUCCESS',
    RESCHEDULE_THE_INSTALLMENT_FAILURE: 'RESCHEDULE_THE_INSTALLMENT_FAILURE',

    EDIT_CONTRACT_REQUEST: 'EDIT_CONTRACT_REQUEST',
    EDIT_CONTRACT_SUCCESS: 'EDIT_CONTRACT_SUCCESS',
    EDIT_CONTRACT_FAILURE: 'EDIT_CONTRACT_FAILURE',

    EXTEND_CONTRACT_REQUEST: 'EXTEND_CONTRACT_REQUEST',
    EXTEND_CONTRACT_SUCCESS: 'EXTEND_CONTRACT_SUCCESS',
    EXTEND_CONTRACT_FAILURE: 'EXTEND_CONTRACT_FAILURE',

    UPDATE_INSTALLMENT_REQUEST: 'UPDATE_INSTALLMENT_REQUEST',
    UPDATE_INSTALLMENT_SUCCESS: 'UPDATE_INSTALLMENT_SUCCESS',
    UPDATE_INSTALLMENT_FAILURE: 'UPDATE_INSTALLMENT_FAILURE',

    COLLECT_FILE_REQUEST: 'COLLECT_FILE_REQUEST',
    COLLECT_FILE_SUCCESS: 'COLLECT_FILE_SUCCESS',
    COLLECT_FILE_FAILURE: 'COLLECT_FILE_FAILURE',

    CLEAR_STATES_RELATED_TO_EDIT_CONTRACT_COMPONENT: 'CLEAR_STATES_RELATED_TO_EDIT_CONTRACT_COMPONENT',
    CLEAR_STATES_RELATED_TO_TERMINATE_CONTRACT_COMPONENT: 'CLEAR_STATES_RELATED_TO_TERMINATE_CONTRACT_COMPONENT',
    CLEAR_STATES_RELATED_TO_RESCHEDULING_INSTALLMENT_COMPONENT: 'CLEAR_STATES_RELATED_TO_RESCHEDULING_INSTALLMENT_COMPONENT',
    CLEAR_STATES_RELATED_TO_EXTEND_CONTRACT_COMPONENT: 'CLEAR_STATES_RELATED_TO_EXTEND_CONTRACT_COMPONENT',
    CLEAR_STATES_RELATED_TO_UPDATE_INSTALLMENT_COMPONENT: 'CLEAR_STATES_RELATED_TO_UPDATE_INSTALLMENT_COMPONENT',
    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE'
};
