import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for loans*/
const initialState= {
    smartBatteries: undefined,
    errorMessageRegardingSmartBatteryListCollection: undefined,
    smartDongles: undefined,
    errorMessageRegardingDongleListCollection: undefined,
    borrowers: undefined,
    errorMessageRegardingBorrowerListCollection: undefined,
    garagesForFilterField: undefined,
    errorMessageRegardingGarageListCollection: undefined,
    financiers: undefined,
    loans: undefined,
    loanDetails: undefined,
    operationalLogsForEditContract: undefined,
    operationalLogsForInstallmentUpdate: undefined,
    loansForExport: undefined,
    garageDetails: undefined,
    providedSmartBatteries: undefined,
    updateGarageActivation: undefined,
    statusUpdateFailed: false,
    transactions: undefined,
    transactionsForExport: undefined,
    garageDueLimitUpdated: undefined,
    driverList: undefined,
    loanTerminated: undefined,
    errorMessageForLoanTermination: undefined,
    loanCreated: undefined,
    errorMessageForLoanCreation: undefined,
    rescheduled: undefined,
    errorMessageForReschedule: undefined,
    contractEdited: undefined,
    errorMessageForContractEdit: undefined,
    contractExtended: undefined,
    errorMessageForContractExtend: undefined,
    installmentUpdated: undefined,
    errorMessageForInstallmentUpdate: undefined,
    file: undefined,
    errorRelatedToFileCollection: undefined
};

const initialStateForEditContract= {
    contractEdited: undefined,
    errorMessageForContractEdit: undefined
};

const initialStateForTerminateContract= {
    loanTerminated: undefined,
    errorMessageForLoanTermination: undefined,
};

const initialStateForReschedulingInstallment= {
    rescheduled: undefined,
    successMessageForReschedule: undefined,
    errorMessageForReschedule: undefined
};

const initialStateForExtendContract= {
    contractExtended: undefined,
    errorMessageForContractExtend: undefined
};

const initialStateForUpdateInstallment= {
    installmentUpdated: undefined,
    errorMessageForInstallmentUpdate: undefined
};

/*Reducer for Loan's redux store*/
export const contractManagementReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_FINANCIERS_REQUEST:
            return {
                ...state,
                financiers: undefined,
                collectingFinanciers: true
            };
        case actionTypes.COLLECT_FINANCIERS_SUCCESS:
            return {
                ...state,
                financiers: action.payload,
                collectingFinanciers: false
            };
        case actionTypes.COLLECT_FINANCIERS_FAILURE:
            toast.error("Can't collect the financier list! Please contact with admin.");
            return {
                ...state,
                collectingFinanciers: false
            };

        case actionTypes.COLLECT_LOAN_LIST_REQUEST:
            return {
                ...state,
                loans: undefined,
                loanCollectionInProgress: true
            };
        case actionTypes.COLLECT_LOAN_LIST_SUCCESS:
            return {
                ...state,
                loans: action.payload,
                loanCollectionInProgress: false
            };
        case actionTypes.COLLECT_LOAN_LIST_FAILURE:
            toast.error("Can't collect the loan list! Please contact with an admin.");
            return {
                ...state,
                loanCollectionInProgress: false
            };

        case actionTypes.COLLECT_LOAN_DETAILS_REQUEST:
            return {
                ...state,
                loanDetails: undefined,
                loanDetailsCollectionInProgress: true
            };
        case actionTypes.COLLECT_LOAN_DETAILS_SUCCESS:
            return {
                ...state,
                loanDetails: action.payload,
                loanDetailsCollectionInProgress: false
            };
        case actionTypes.COLLECT_LOAN_DETAILS_FAILURE:
            toast.error("Can't collect all details information! Please contact with an admin.");
            return {
                ...state,
                loanDetailsCollectionInProgress: false
            };

        case actionTypes.COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_REQUEST:
            return {
                ...state,
                operationalLogsForEditContract: undefined,
                editContractLogCollectionInProgress: true
            };
        case actionTypes.COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_SUCCESS:
            return {
                ...state,
                operationalLogsForEditContract: action.payload,
                editContractLogCollectionInProgress: false
            };
        case actionTypes.COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_FAILURE:
            toast.error("Can't collect operational logs for edit contract! Please contact with an admin.");
            return {
                ...state,
                editContractLogCollectionInProgress: false
            };

        case actionTypes.COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_REQUEST:
            return {
                ...state,
                operationalLogsForInstallmentUpdate: undefined,
                installmentUpdateLogCollectionInProgress: true
            };
        case actionTypes.COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_SUCCESS:
            return {
                ...state,
                operationalLogsForInstallmentUpdate: action.payload,
                installmentUpdateLogCollectionInProgress: false
            };
        case actionTypes.COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_FAILURE:
            toast.error("Can't collect operational logs for installment update! Please contact with an admin.");
            return {
                ...state,
                installmentUpdateLogCollectionInProgress: false
            };

        case actionTypes.COLLECT_LOAN_LIST_FOR_EXPORT_REQUEST:
            return {
                ...state,
                loansForExport: undefined,
                exportDataCollectionInProgress: true
            };
        case actionTypes.COLLECT_LOAN_LIST_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                loansForExport: action.payload,
                exportDataCollectionInProgress: false
            };
        case actionTypes.COLLECT_LOAN_LIST_FOR_EXPORT_FAILURE:
            toast.error("Can't collect data for export! Please contact with an admin.");
            return {
                ...state,
                exportDataCollectionInProgress: false
            };

        case actionTypes.COLLECT_SMART_BATTERY_LIST_REQUEST:
            return {
                ...state,
                smartBatteries: undefined,
                errorMessageRegardingSmartBatteryListCollection: undefined,
                smartBatteryListDataCollectionInProgress: true
            };
        case actionTypes.COLLECT_SMART_BATTERY_LIST_SUCCESS:
            return {
                ...state,
                smartBatteries: action.payload,
                smartBatteryListDataCollectionInProgress: false
            };
        case actionTypes.COLLECT_SMART_BATTERY_LIST_FAILURE:
            return {
                ...state,
                errorMessageRegardingSmartBatteryListCollection: action.payload.error,
                smartBatteryListDataCollectionInProgress: false
            };

        case actionTypes.COLLECT_SMART_DONGLE_LIST_REQUEST:
            return {
                ...state,
                smartDongles: undefined,
                errorMessageRegardingDongleListCollection: undefined,
                dongleListCollectionInProgress: true
            };
        case actionTypes.COLLECT_SMART_DONGLE_LIST_SUCCESS:
            return {
                ...state,
                smartDongles: action.payload,
                dongleListCollectionInProgress: false
            };
        case actionTypes.COLLECT_SMART_DONGLE_LIST_FAILURE:
            return {
                ...state,
                errorMessageRegardingDongleListCollection: action.payload.error,
                dongleListCollectionInProgress: false
            };

        case actionTypes.COLLECT_BORROWER_LIST_REQUEST:
            return {
                ...state,
                borrowers: undefined,
                errorMessageRegardingBorrowerListCollection: undefined,
                borrowerListCollectionInProgress: true
            };
        case actionTypes.COLLECT_BORROWER_LIST_SUCCESS:
            return {
                ...state,
                borrowers: action.payload,
                borrowerListCollectionInProgress: false
            };
        case actionTypes.COLLECT_BORROWER_LIST_FAILURE:
            return {
                ...state,
                errorMessageRegardingBorrowerListCollection: action.payload.error,
                borrowerListCollectionInProgress: false
            };

        case actionTypes.COLLECT_GARAGE_LIST_REQUEST:
            return {
                ...state,
                garagesForFilterField: undefined,
                errorMessageRegardingGarageListCollection: undefined,
                garageListCollectionInProgress: true
            };
        case actionTypes.COLLECT_GARAGE_LIST_SUCCESS:
            return {
                ...state,
                garagesForFilterField: action.payload,
                garageListCollectionInProgress: false
            };
        case actionTypes.COLLECT_GARAGE_LIST_FAILURE:
            return {
                ...state,
                errorMessageRegardingGarageListCollection: action.payload.error,
                garageListCollectionInProgress: false
            };

        case actionTypes.COLLECT_DRIVER_LIST_REQUEST:
            return {
                ...state,
                driverListLoading: true,
                driverList: undefined
            };
        case actionTypes.COLLECT_DRIVER_LIST_SUCCESS:
            return {
                ...state,
                driverListLoading: false,
                driverList: action.payload
            };
        case actionTypes.COLLECT_DRIVER_LIST_FAILURE:
            return {
                ...state,
                driverListLoading: false
            };

        case actionTypes.TERMINATE_LOAN_CONTRACT_REQUEST:
            return {
                ...state,
                loanTerminated: undefined,
                loanTerminationInProgress: true
            };
        case actionTypes.TERMINATE_LOAN_CONTRACT_SUCCESS:
            return {
                ...state,
                loanTerminated: requestCycle.success,
                loanTerminationInProgress: false,
                successMessageForLoanTermination: action.payload
            };
        case actionTypes.TERMINATE_LOAN_CONTRACT_FAILURE:
            return {
                ...state,
                loanTerminated: requestCycle.failed,
                loanTerminationInProgress: false,
                errorMessageForLoanTermination: action.payload.error
            };

        case actionTypes.CREATE_LOAN_CONTRACT_REQUEST:
            return {
                ...state,
                loanCreated: undefined,
                loanCreationInProgress: true
            };
        case actionTypes.CREATE_LOAN_CONTRACT_SUCCESS:
            return {
                ...state,
                loanCreated: requestCycle.success,
                loanCreationInProgress: false,
                successMessageForLoanCreation: action.payload
            };
        case actionTypes.CREATE_LOAN_CONTRACT_FAILURE:
            return {
                ...state,
                loanCreated: requestCycle.failed,
                loanCreationInProgress: false,
                errorMessageForLoanCreation: action.payload.error
            };

        case actionTypes.RESCHEDULE_THE_INSTALLMENT_REQUEST:
            return {
                ...state,
                rescheduled: undefined,
                reschedulingInProgress: true
            };
        case actionTypes.RESCHEDULE_THE_INSTALLMENT_SUCCESS:
            return {
                ...state,
                rescheduled: requestCycle.success,
                reschedulingInProgress: false,
                successMessageForReschedule: action.payload
            };
        case actionTypes.RESCHEDULE_THE_INSTALLMENT_FAILURE:
            return {
                ...state,
                rescheduled: requestCycle.failed,
                reschedulingInProgress: false,
                errorMessageForReschedule: action.payload.error
            };

        case actionTypes.EDIT_CONTRACT_REQUEST:
            return {
                ...state,
                contractEdited: undefined,
                contractEditInProgress: true
            };
        case actionTypes.EDIT_CONTRACT_SUCCESS:
            return {
                ...state,
                contractEdited: requestCycle.success,
                contractEditInProgress: false
            };
        case actionTypes.EDIT_CONTRACT_FAILURE:
            return {
                ...state,
                contractEdited: requestCycle.failed,
                contractEditInProgress: false,
                errorMessageForContractEdit: action.payload.error
            };

        case actionTypes.EXTEND_CONTRACT_REQUEST:
            return {
                ...state,
                contractExtended: undefined,
                contractExtendInProgress: true
            };
        case actionTypes.EXTEND_CONTRACT_SUCCESS:
            return {
                ...state,
                contractExtended: requestCycle.success,
                contractExtendInProgress: false
            };
        case actionTypes.EXTEND_CONTRACT_FAILURE:
            return {
                ...state,
                contractExtended: requestCycle.failed,
                contractExtendInProgress: false,
                errorMessageForContractExtend: action.payload.error
            };

        case actionTypes.UPDATE_INSTALLMENT_REQUEST:
            return {
                ...state,
                installmentUpdated: undefined,
                installmentUpdateInProgress: true
            };
        case actionTypes.UPDATE_INSTALLMENT_SUCCESS:
            return {
                ...state,
                installmentUpdated: requestCycle.success,
                installmentUpdateInProgress: false
            };
        case actionTypes.UPDATE_INSTALLMENT_FAILURE:
            return {
                ...state,
                installmentUpdated: requestCycle.failed,
                installmentUpdateInProgress: false,
                errorMessageForInstallmentUpdate: action.payload.error
            };

        case actionTypes.COLLECT_FILE_REQUEST:
            return {
                ...state,
                file: undefined,
                collectingFile: true
            };
        case actionTypes.COLLECT_FILE_SUCCESS:
            return {
                ...state,
                file: action.payload,
                collectingFile: false
            };
        case actionTypes.COLLECT_FILE_FAILURE:
            return {
                ...state,
                collectingFile: false,
                errorRelatedToFileCollection: action.payload.error
            };

        case actionTypes.CLEAR_STATES_RELATED_TO_EDIT_CONTRACT_COMPONENT:
            return {
                ...state,
                ...initialStateForEditContract
            };

        case actionTypes.CLEAR_STATES_RELATED_TO_TERMINATE_CONTRACT_COMPONENT:
            return {
                ...state,
                ...initialStateForTerminateContract
            };

        case actionTypes.CLEAR_STATES_RELATED_TO_RESCHEDULING_INSTALLMENT_COMPONENT:
            return {
                ...state,
                ...initialStateForReschedulingInstallment
            };

        case actionTypes.CLEAR_STATES_RELATED_TO_EXTEND_CONTRACT_COMPONENT:
            return {
                ...state,
                ...initialStateForExtendContract
            };

        case actionTypes.CLEAR_STATES_RELATED_TO_UPDATE_INSTALLMENT_COMPONENT:
            return {
                ...state,
                ...initialStateForUpdateInstallment
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
