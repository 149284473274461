import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_HARDWARE_LIST: (filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler(`/hardwares`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_HARDWARE_LIST_REQUEST,
                actionTypes.GET_HARDWARE_LIST_SUCCESS,
                actionTypes.GET_HARDWARE_LIST_FAILURE
            ]
        }
    }),

    GET_FIRMWARE_LIST: (filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/firmwares', filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_FIRMWARE_LIST_REQUEST,
                actionTypes.GET_FIRMWARE_LIST_SUCCESS,
                actionTypes.GET_FIRMWARE_LIST_FAILURE
            ]
        }
    }),

    CREATE_FIRMWARE_LIST: (payload = {}) => ({
        [CALL_API]: {
            endpoint: '/firmwares',
            method: 'POST',
            body: payload,
            formDataFlag: true,
            backend_slug: 'device_management',
            types: [
                actionTypes.CREATE_FIRMWARE_LIST_REQUEST,
                actionTypes.CREATE_FIRMWARE_LIST_SUCCESS,
                actionTypes.CREATE_FIRMWARE_LIST_FAILURE,
            ]
        }
    }),

    UPDATE_FIRMWARE: (payload = {}) => ({
        [CALL_API]: {
            endpoint: '/firmwares',
            method: 'PUT',
            body: payload,
            formDataFlag: true,
            backend_slug: 'device_management',
            types: [
                actionTypes.UPDATE_FIRMWARE_REQUEST,
                actionTypes.UPDATE_FIRMWARE_SUCCESS,
                actionTypes.UPDATE_FIRMWARE_FAILURE,
            ]
        }
    }),

    // TODO: Need to rename the action and the action type
    CREATE_HARDWARE_LIST: (payload = {}) => ({
        [CALL_API]: {
            endpoint: '/hardwares',
            method: 'POST',
            body: payload,
            backend_slug: 'device_management',
            types: [
                actionTypes.CREATE_HARDWARE_LIST_REQUEST,
                actionTypes.CREATE_HARDWARE_LIST_SUCCESS,
                actionTypes.CREATE_HARDWARE_LIST_FAILURE,
            ]
        }
    }),

    UPDATE_HARDWARE: (payload = {}) => ({
        [CALL_API]: {
            endpoint: '/hardwares',
            method: 'PUT',
            body: payload,
            backend_slug: 'device_management',
            types: [
                actionTypes.UPDATE_HARDWARE_REQUEST,
                actionTypes.UPDATE_HARDWARE_SUCCESS,
                actionTypes.UPDATE_HARDWARE_FAILURE,
            ]
        }
    }),

    GET_FIRMWARE_UPDATE_LIST: (filter = {}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/firmware-update-history', filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_FIRMWARE_UPDATE_LIST_REQUEST,
                actionTypes.GET_FIRMWARE_UPDATE_LIST_SUCCESS,
                actionTypes.GET_FIRMWARE_UPDATE_LIST_FAILURE,
            ]
        }
    }),
    RETURN_TO_INITIAL: () => ({type: "RETURN_TO_INITIAL"})
}
