import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    GET_ROLE_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/roles', filter),
            method: 'GET',
            types: [
                actionTypes.GET_ROLE_LIST_REQUEST,
                actionTypes.GET_ROLE_LIST_SUCCESS,
                actionTypes.GET_ROLE_LIST_FAILURE
            ]
        }
    }),

    GET_ORGANISATIONS: ()=>({
        [CALL_API]: {
            endpoint: `/organizations`,
            method: 'GET',
            types: [
                actionTypes.GET_ORGANISATIONS_REQUEST,
                actionTypes.GET_ORGANISATIONS_SUCCESS,
                actionTypes.GET_ORGANISATIONS_FAILURE,
            ]
        }
    }),

    COLLECT_FEATURE_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/roles/permission',
            method: 'GET',
            types: [
                actionTypes.COLLECT_FEATURE_LIST_REQUEST,
                actionTypes.COLLECT_FEATURE_LIST_SUCCESS,
                actionTypes.COLLECT_FEATURE_LIST_FAILURE
            ]
        }
    }),

    CREATE_ROLE: (payload)=>({
        [CALL_API]: {
            endpoint: '/roles',
            method: 'POST',
            body: payload,
            types: [
                actionTypes.CREATE_ROLE_REQUEST,
                actionTypes.CREATE_ROLE_SUCCESS,
                actionTypes.CREATE_ROLE_FAILURE
            ]
        }
    }),

    UPDATE_ROLE: (role_guid, payload)=>({
        [CALL_API]: {
            endpoint: '/roles/' + role_guid,
            method: 'POST',
            body: payload,
            types: [
                actionTypes.UPDATE_ROLE_REQUEST,
                actionTypes.UPDATE_ROLE_SUCCESS,
                actionTypes.UPDATE_ROLE_FAILURE
            ]
        }
    }),
};
