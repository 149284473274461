import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {Button, Form, Spinner} from "react-bootstrap";
import {SolInput} from "../../../components/SolStyledComponents/components";
import Select from "react-select";
import {useForm, Controller} from "react-hook-form";
import {connect} from "react-redux";
import {actions} from "../actions";
import {requestCycle} from "../utils";
import {toast} from "react-toastify";
import {Toolbar} from "../../users/views/components";
import {useSubheader} from "../../../../_metronic/layout";
import { da } from 'date-fns/locale';


const CreateFirmware = props => {
    const validFirmwareTypes=[
        "firmware",
        "bootloader",
        "test_firmware",
        "initial_firmware",
    ]

    const firmwareTypes = [
        {value: validFirmwareTypes[0], label: "Operation Firmware"},
        {value: validFirmwareTypes[1], label: "Bootloader Firmware"},
        { value: validFirmwareTypes[2], label: "Test Firmware" },
        { value: validFirmwareTypes[3], label: "Initial Firmware" },
    ]
    
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const [language, setLanguage] = useState('EN')
    const location = useLocation()
    const subHeader = useSubheader();

    const [edit, setEdit] = useState(!!sessionStorage.getItem('edit'))
    const [firmwareGuid, setFirmwareGuid] = useState(sessionStorage.getItem('pk')? sessionStorage.getItem('pk'):'')
    const [version, setVersion] = useState(sessionStorage.getItem('version')? sessionStorage.getItem('version'):'')
    const [status, setStatus] = useState(sessionStorage.getItem('status')? sessionStorage.getItem('status'):false)
    const [hardwares, setHardwares] = useState([])
    const [firmwareType, setFirmwareType] = useState(sessionStorage.getItem('entity_type') ? sessionStorage.getItem('entity_type') : '')
    const [selectedHardwares, setSelectedHardwares] = useState(sessionStorage.getItem('hardwares')? JSON.parse(sessionStorage.getItem('hardwares')):'')
    useEffect(()=>{
        setLanguage(props.language)
        props.GET_HARDWARE_LIST()
        subHeader.setActionButtons(null)
        if (location.purpose && location.purpose === 'create') {
            setEdit(false)
            sessionStorage.setItem('edit', false)
        }
        if (location.firmwareData) {
            sessionStorage.setItem('edit', true)
            let firmware = location.firmwareData.pk
            sessionStorage.setItem('pk', firmware)
            let version = location.firmwareData.version
            sessionStorage.setItem("version", version)
            let firmwareStatus = location.firmwareData.status
            sessionStorage.setItem("status", firmwareStatus)
            let hardwares = []
            if (location.firmwareData.hardware_version_list) {
                hardwares = location.firmwareData.hardware_version_list.map((item)=> {
                    return {
                        value: item,
                        label: item
                    }
                })
            }
            sessionStorage.setItem("hardwares", JSON.stringify(hardwares))
            setEdit(true)
            setFirmwareGuid(firmware)
            setVersion(version)
            setStatus(firmwareStatus)
            setSelectedHardwares(hardwares)
            setFirmwareType(location.firmwareData.entity_type)
            sessionStorage.setItem("entity_type", location.firmwareData.entity_type)
        }
    },[])
    useEffect(()=>{
        if (sessionStorage.getItem('edit') === 'false') {
            setFirmwareGuid("")
            setVersion("")
            setStatus(false)
            setSelectedHardwares([])
            clearSessionStorages()
        }
    },[edit])

    useEffect(()=>{
        const temp = props.hardwareList;
        if (Array.isArray(temp)){
            setHardwares(temp.map((item)=>{
                return {
                    value: item.version,
                    label: item.version
                }
            }))
        }
    },[props.hardwareList])

    useEffect(() => {
        if (props.firmwareCreated === requestCycle.success) {
            if (language === 'EN') {
                toast.success("Firmware Created Successfully!");
            } else {
                toast.success("সফলভাবে ফার্মওয়্যার তৈরি হয়েছে!");
            }
            props.history.push('/ota/firmwares')
        } else if (props.firmwareCreated === requestCycle.failed) {
            if (language === 'EN') {
                toast.error("Can't create Firmware. " + props.errorMessage)
            } else {
                toast.error("ফার্মওয়্যার তৈরি করা সম্ভব হচ্ছে না!")
            }
        }
    }, [props.firmwareCreated])

    useEffect(() => {
        if (props.firmwareUpdated === requestCycle.success) {
            if (language === 'EN') {
                toast.success("Firmware is updated Successfully!");
            } else {
                toast.success("সফলভাবে ফার্মওয়্যার সম্পাদনা হয়েছে!");
            }
            props.history.goBack()
        } else if (props.firmwareUpdated === requestCycle.failed) {
            if (language === 'EN') {
                toast.error("Can't update Firmware. " + props.errorMessageForHardwareUpdate)
            } else {
                toast.error("ফার্মওয়্যার সম্পাদনা করা সম্ভব হচ্ছে না!")
            }
        }
    }, [props.firmwareUpdated])

    const clearSessionStorages = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('pk')
        sessionStorage.removeItem('version')
        sessionStorage.removeItem('status')
        sessionStorage.removeItem('hardwares')
    }

    const validateFirmwareType = (data)=>{
        const hex_file = ["bootloader", "test_firmware"]

        if (hex_file.indexOf(data["type"]) !== -1 && !data?.firmware_file.name?.endsWith('.hex')) {
            setError("firmware_file", {
                message: "Not a valid hex file"
            })
            return
        } else if (hex_file.indexOf(data["type"]) === -1 && !data?.firmware_file.name?.endsWith('.zip')) {
            setError("firmware_file", {
                message: "Not a valid zip file"
            })
            return
        }

        return true
    }

    const createFirmware = (data) => {
        data = {
            ...data,
            status: data.status ? 'active' : 'inactive',
            firmware_file: data.firmware_file['0'],
            hardware_version_list: Array.isArray(selectedHardwares) ? selectedHardwares.map((item) => (item.value.toString())).toString() : [].toString()
        }
        data["type"] = data.type?.value || undefined

        if (!validateFirmwareType(data)) return

        props.CREATE_FIRMWARE_LIST(data)
    }

    const updateFirmware =(data)=>{
        data = {
            ...data,
            pk: firmwareGuid,
            status: data.status ? 'active' : 'inactive',
            hardware_version_list: Array.isArray(selectedHardwares) ? selectedHardwares.map((item) => (item.value.toString())).toString() : [].toString()
        }
        if (data.type?.value || firmwareType) {
            data["type"] = firmwareType
        }
        if (data.firmware_file['0']) {
            data = {
                ...data,
                firmware_file: data.firmware_file['0'],
            }
            if (!validateFirmwareType(data)) return

        }
        props.UPDATE_FIRMWARE(data);
    }

    const onSubmit=(data)=>{

        if(!Array.isArray(selectedHardwares) || selectedHardwares.length<=0){
            toast.error("Select at least one valid hardware");
            return
        }
        if (!edit) {
            createFirmware(data)
        } else {
            updateFirmware(data)
        }
    }

    return (
        <ContentWrapper pageTitle={''} showCardHeader={false} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{!edit? (language === "EN"? "Create Firmware":"ফার্মওয়্যার তৈরি করুন"):(language === "EN"? "Update Firmware":"ফার্মওয়্যার সম্পাদনা করুন")}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Form onSubmit={handleSubmit(onSubmit)}>


                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                {!edit?<Form.Group>
                                    <Form.Label>{language === 'EN'? 'Firmware Version':'ফার্মওয়্যার ভার্সন'}{!edit?<span className="required text-danger">*</span>:null}</Form.Label>
                                    <SolInput
                                        type="text"
                                        name={"version"}
                                        // value={version}
                                        disabled={!!edit}
                                        ref={register({
                                            required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                            pattern: {
                                                value: /^\d+(\.\d+)*$/i,
                                                message: language === "EN"? "Invalid firmware version!":"ফার্মওয়্যার ভার্সনটি সঠিক নয়!"
                                            }
                                        })}
                                        placeholder={language === "EN"? "Enter Firmware Version. Ex: 1.1, 1.1.1":"ফার্মওয়্যার ভার্সন লিখুন।উদাহরণঃ 1.1, 1.1.1"}
                                        autoComplete={"off"}
                                        // onChange={(e) => {
                                        //     if (e.target.value) {
                                        //         setVersion(e.target.value)
                                        //     } else {
                                        //         setVersion("")
                                        //     }
                                        // }}
                                    />
                                    {errors.version && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.version.message}</div>}

                                </Form.Group>: <div>
                                            <h3>Changing Firmware Info for {version}</h3>
                                    </div>}
                            </div>
                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        name={'status'}
                                        label={language === "EN"? "Active":"সক্রিয়"}
                                        ref={register()}
                                        checked={status}
                                        onChange={() => {setStatus(!status)}}
                                    />
                                    {errors.status && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.status.message}</div>}
                                </Form.Group>
                            </div>
                        </div>

                        { <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group>
                                    <Form.Control
                                        type="file"
                                        name={'firmware_file'}
                                        ref={register({
                                            required: !edit?'Required': false,
                                        })}
                                    />
                                    {errors.firmware_file && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.firmware_file.message}</div>}
                                </Form.Group>
                            </div>
                        </div>}

                        <div className={"col-md-6 no-padding"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>{language === "EN"? "Compatible Hardwares":"ফার্মওয়্যারের জন্য উপযুক্ত হার্ডওয়্যারসমূহ"}<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <Select
                                    name={`hardwares`}
                                    placeholder={language === 'EN'? 'Select Hardwares':'ফার্মওয়্যারের জন্য উপযুক্ত হার্ডওয়্যারসমূহ বাছাই করুন..'}
                                    classNamePrefix="react-select-sol-style"
                                    isDisabled={props.filterDropdownLoading}
                                    isLoading={props.filterDropdownLoading}
                                    maxMenuHeight={200}
                                    // value={hardwares}
                                    isClearable={true}
                                    control={control}
                                    options={hardwares}
                                    isMulti={true}
                                    isSearchable={true}
                                    value={selectedHardwares}
                                    onChange={(selected) => {
                                        clearErrors();
                                        if (selected) {
                                            setSelectedHardwares(selected)
                                        } else {
                                            setSelectedHardwares('')
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>


                        {!edit?<div className={"col-md-6 no-padding"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>{language === "EN"? "Firmware Type":"ফার্মওয়্যারের জন্য উপযুক্ত হার্ডওয়্যারসমূহ"}<span className="required text-danger">*</span></Form.Label>
                                </div>
                                <Controller
                                           control={control}
                                           name={"type"}
                                           rules= {{ required: "Required" }}
                                           defaultValue={firmwareTypes[0]}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={`type`}
                                                   placeholder={language === 'EN'? 'Select a Firmware type':'ব্যাটারি মডেল বাছাই করুন'}
                                                   classNamePrefix="react-select-sol-style"
                                                   isDisabled={props.batteryModelListDropDownLoading}
                                                   isLoading={props.batteryModelListDropDownLoading}
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={firmwareTypes}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                    onChange(selected)   
                                                    return selected;
                                                   }}
                                               />
                                           )}
                                       />
                            </Form.Group>
                        </div>:null}


                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Button variant="dark" size={'md'} onClick={() => {
                                    clearSessionStorages()
                                    props.history.goBack()
                                }} style={{
                                    backgroundColor: '#8C8C9B',
                                    outline: '#8C8C9B',
                                    border: '#8C8C9B',
                                }}>
                                    <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Close':'বন্ধ করুন'}
                                </Button>
                                <Button variant="warning" type="submit" className={"ml-3"} disabled={props.firmwareInfoSubmitting}>
                                    {props.firmwareInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}<i className='fa fa-check' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Save':'সংরক্ষণ করুন'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>


        </ContentWrapper>
    );
};

CreateFirmware.propTypes = {

};
export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.otaReducer.isLoading,
        hardwareList: state.otaReducer.hardwareList,
        firmwareCreated: state.otaReducer.firmwareCreated,
        firmwareUpdated: state.otaReducer.firmwareUpdated,
        firmwareInfoSubmitting: state.otaReducer.firmwareInfoSubmitting,
        errorMessage: state.otaReducer.errorMessage,
        errorMessageForHardwareUpdate: state.otaReducer.errorMessageForHardwareUpdate,
        listLoading: state.otaReducer.listLoading
    }
}, {...actions}) (CreateFirmware);
