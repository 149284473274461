import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import {DataTable, SolInput} from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {Button, Spinner, Form, Row, Col} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";
import {SolTextArea} from "../../../../../components/SolStyledComponents/components";
import {DataTableContainerForLoanDetails} from "../../../utils";


const ExtendContract = ({ contractDetails, closeExtendProcess, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [language, setLanguage] = useState("EN");

    const [installments, setInstallments] = useState([]);
    const [overdueInvoiceCount, setOverdueInvoiceCount] = useState(0);
    const [overdueInvoiceAmount, setOverdueInvoiceAmount] = useState(0);
    const [extendedContractCount, setExtendedContractCount] = useState(0);

    const overdueInstallmentsTableColumns = [
        {
            field: 'installment_no',
            title: "Installment #",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.installment_no}</strong></h6>
                </>
            }
        },
        {
            field: 'due_date',
            title: "Schedule",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    {/* Payment date is shown based on the exact same logic used in the "Repayment Schedule" section */}
                    <h6><strong>{rowData.rescheduled? moment(rowData.due_date, "YYYY-MM-DD").format("MMM DD, YYYY"):moment(rowData.due_date, "YYYY-MM-DD").subtract(1, 'days').format("MMM DD, YYYY")}</strong></h6>
                </>
            }
        },
        {
            field: 'payable_amount',
            title: "Amount Payable",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{new Intl.NumberFormat('en-IN').format(rowData.payable_amount.toFixed(2))}</strong></h6>
                </>
            }
        }
    ]

    const {
        EXTEND_CONTRACT,
        COLLECT_LOAN_DETAILS,
        CLEAR_STATES_RELATED_TO_EXTEND_CONTRACT_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        let loanSchedules = contractDetails.loan_schedules
        let overdueInstallments = []
        let totalOverDue = 0
        for (let i=0; i<loanSchedules.length; i++) {
            if (loanSchedules[i].status === 'overdue') {
                overdueInstallments.push(loanSchedules[i])
                totalOverDue += loanSchedules[i].payable_amount
            }
        }
        setInstallments(overdueInstallments)
        setOverdueInvoiceCount(overdueInstallments.length)
        setOverdueInvoiceAmount(totalOverDue)

        return ()=> {
            CLEAR_STATES_RELATED_TO_EXTEND_CONTRACT_COMPONENT()
        }
    }, [])

    const extend = (data) => {
        let payload = {
            "contract_id": contractDetails.contract_id,
            "number_of_installment": parseInt(data.number_of_installments),
            "reason": data.reason
        }
        EXTEND_CONTRACT(payload)
    }

    useEffect(() => {
        if (props.contractExtended && props.contractExtended === requestCycle.success) {
            toast.success("Contract extended successfully!")
            closeExtendProcess()
            COLLECT_LOAN_DETAILS(contractDetails.contract_id)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.contractExtended])

    useEffect(() => {
        if (props.errorMessageForContractExtend) {
            showNotifications('error', props.errorMessageForContractExtend)
        }
    }, [props.errorMessageForContractExtend])

    return (
        <>
            <Form onSubmit={handleSubmit(extend)}>
                {overdueInvoiceCount > 0? <>
                    <div className={'row g-3'}>
                        <div className={'col-md-12'}>
                            <h6><strong>Number of overdue {overdueInvoiceCount > 1? 'invoices':'invoice'}: {overdueInvoiceCount}</strong></h6>
                            <h6><strong>Total overdue: BDT {new Intl.NumberFormat('en-IN').format(overdueInvoiceAmount.toFixed(2))}</strong></h6>
                        </div>
                    </div>
                    <div className={'row g-3'}>
                        <div className={'col-md-12'}>
                            <DataTableContainerForLoanDetails>
                                <DataTable
                                    language={language}
                                    columns={overdueInstallmentsTableColumns}
                                    data={installments}
                                    showToolbar={false}
                                    asyncPagination={false}
                                    pagination={installments.length > 5}
                                    pageSize={5}
                                />
                            </DataTableContainerForLoanDetails>
                        </div>
                    </div>
                    <div className={'row g-3 mt-5'}>
                        <div className={'col-md-12'}>
                            <Form.Group as={Row}>
                                <Form.Label column sm={1}><h6><strong>Extend</strong></h6></Form.Label>
                                <Col sm={4}>
                                    <SolInput
                                        name={'number_of_installments'}
                                        type={"number"}
                                        min={"1"}
                                        step={"1"}
                                        max={overdueInvoiceCount}
                                        placeholder={"Type a number"}
                                        autoComplete={"off"}
                                        disabled={props.contractExtendInProgress}
                                        ref={register({
                                            required: "Above information is required!"
                                        })}
                                        onWheel={(event) => event.target.blur()}
                                        onChange={(event) => {
                                            if (event.target.value) {
                                                setExtendedContractCount(parseInt(event.target.value))
                                            } else {
                                                setExtendedContractCount(0)
                                            }
                                        }}
                                    />
                                    {errors.number_of_installments && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.number_of_installments.message}</div>}
                                </Col>
                                <Form.Label column sm={7}><h6><strong>overdue {extendedContractCount <=1? 'invoice':'invoices'}</strong></h6></Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-3'}>
                        <div className={'col-md-12'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><h6><strong>Reason</strong> <span className="required text-danger">*</span></h6></Form.Label>
                                </div>
                                <SolTextArea
                                    name={"reason"}
                                    rows={"3"}
                                    placeholder={"Reason for extension"}
                                    autoComplete={"off"}
                                    disabled={props.contractExtendInProgress}
                                    ref={register({
                                        required: "Above information is required!"
                                    })}
                                />
                                {errors.reason && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reason.message}</div>}
                            </Form.Group>
                        </div>
                    </div>
                </>:<>
                    <div className={'row g-3'}>
                        <div className={'col-md-12 d-flex justify-content-center align-items-center'}>
                            <h6><strong>No overdue invoices are available to extend</strong></h6>
                        </div>
                    </div>
                </>}
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Button variant={"outline-dark"} type={"button"} size={'lg'}
                                    disabled={props.contractExtendInProgress}
                                    onClick={closeExtendProcess}>
                                Cancel
                            </Button>
                            <Button variant="warning" size={'lg'} type="submit" disabled={props.contractExtendInProgress || overdueInvoiceCount === 0}>
                                {props.contractExtendInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null} Extend
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Form>
        </>
    )
}

ExtendContract.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        contractExtended: state.contractManagementReducer.contractExtended,
        contractExtendInProgress: state.contractManagementReducer.contractExtendInProgress,
        errorMessageForContractExtend: state.contractManagementReducer.errorMessageForContractExtend
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(ExtendContract);